.icon3000 {

  display: inline-flex;

  justify-content: center;

  height: 1em;
  width: 1em;
  vertical-align: -0.125em;
  overflow: visible;
  box-sizing: content-box
}

  .icon3000 svg {

  height: 100%;

  width: 100%;

  pointer-events: none
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uL3Bpc3RhY2hpby9hcHAvY29tcG9uZW50cy9waXN0YWNoaW8vaWNvbl9jb21wb25lbnQuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNFOztFQUFBLG9CQUFpQzs7RUFBakMsdUJBQWlDOztFQUVqQyxXQUFXO0VBQ1gsVUFBVTtFQUNWLHdCQUF3QjtFQUN4QixpQkFBaUI7RUFDakI7QUFOaUM7O0VBUy9COztFQUFBLFlBQW9COztFQUFwQixXQUFvQjs7RUFDcEI7QUFEb0IiLCJmaWxlIjoiLi4vcGlzdGFjaGlvL2FwcC9jb21wb25lbnRzL3Bpc3RhY2hpby9pY29uX2NvbXBvbmVudC5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuaWNvbjMwMDAge1xuICBAYXBwbHkgaW5saW5lLWZsZXgganVzdGlmeS1jZW50ZXI7XG5cbiAgaGVpZ2h0OiAxZW07XG4gIHdpZHRoOiAxZW07XG4gIHZlcnRpY2FsLWFsaWduOiAtMC4xMjVlbTtcbiAgb3ZlcmZsb3c6IHZpc2libGU7XG4gIGJveC1zaXppbmc6IGNvbnRlbnQtYm94O1xuXG4gIHN2ZyB7XG4gICAgQGFwcGx5IGgtZnVsbCB3LWZ1bGw7XG4gICAgQGFwcGx5IHBvaW50ZXItZXZlbnRzLW5vbmU7XG4gIH1cbn1cbiJdfQ== */