import { Controller } from '@hotwired/stimulus'
import { Helpers } from '../../../utils/helpers'
import { toggle } from '../../../utils/visibility'

export default class extends Controller {
  static values = {
    itemHeight: Number,
    numVisibleItems: Number,
    numBufferItems: Number,
  }

  static targets = [
    'listContainer',
    'list',
    'viewport',
    'entry',
    'noEntries',
    'searchInput',
  ]

  connect() {
    this.highlightedValue = null
    this.selectedValues = []
    this.items = []
    this.filterTerm = ''
  }

  markValuesAsSelected(values) {
    this.selectedValues = values
    this.update()
  }

  highlightValue(value) {
    this.highlightedValue = value
    if (this.highlightedItem) {
      this.scrollItemIntoView(this.highlightedItem)
    }
    this.update()
  }

  focus() {
    // After update, focus input (if not on mobile)
    if (this.hasSearchInputTarget && !Helpers.deviceHasNativeFormControls()) {
      this.searchInputTarget.focus()
      this.searchInputTarget.select()
    }
  }

  get highlightedItem() {
    return this.items.find(item => item.value == this.highlightedValue)
  }

  // select sets this
  setSearchTerm(term) {
    if (this.hasSearchInputTarget) {
      this.searchInputTarget.value = term
      this.setFilter(term)
    }
  }

  // input with suggestions sets this directly
  setFilter(term) {
    const escapedTerm = Helpers.escapeRegExp(term)
    if (escapedTerm !== this.filterTerm) {
      this.filterTerm = escapedTerm
      this.updateItems()
    }
  }

  get hasFilter() {
    return this.filterTerm.length > 0
  }

  get filterRegex() {
    return new RegExp(`(^|\\s|\\b)+(${this.filterTerm})([^\\s]*)`, 'i') // no `g`, RegExp is stateful, we might want to `test` multiple times with same string
  }

  updateItems() {
    const regex = this.filterRegex

    this.items = []

    // match group
    const itemsByGroup = {}
    this.options.forEach((option) => {
      const label = option.label
      const group = option.group
      const details = option.details
      const value = option.value

      const display = option.display
      const icon = option.icon

      let gm = (group && !this.hasFilter)
      gm ||= (group && regex.test(group))

      let em = false
      if (display !== 'never') {
        em ||= !this.hasFilter
        em ||= (display === 'always')
        em ||= (label && regex.test(label))
        em ||= (details && regex.test(details))
      }

      em ||= gm // if group matches, show option

      if (em) {
        const item = { type: 'option', label: label, details: details, icon: icon, option: option, value: value, group: group }
        if (group) {
          itemsByGroup[group] ||= []
          itemsByGroup[group].push(item)
        } else {
          this.items.push(item)
        }
      }
    })

    // For grouped entries, make sure they are grouped together before building list
    Object.entries(itemsByGroup).forEach(([group, items]) => {
      this.items.push({ type: 'group', label: group })
      items.forEach((item) => this.items.push(item))
    })

    // Check highlight
    if (!this.highlightedValue || !this.items.some(item => item.value == this.highlightedValue)) {
      const firstOptionItem = this.items.find(item => item.value)
      this.highlightValue(firstOptionItem?.value) // first value
    }

    this.update(true) // force redraw since filter changed
  }

  loadOptions(options) {
    this.options = options
    this.updateItems()
  }

  updateOption(option) {
    const index = this.options.findIndex(o => o.value == option.value)

    if (index === -1) {
      console.log('Warning updateOption: option not found', option)
      return
    }

    // update
    this.options[index] = option

    // re-apply filter
    this.updateItems()
  }

  onEntryMouseDown(event) {
    // do not take over focus from input field for example
    event.preventDefault()
  }

  onClick(event) {
    const entry = event.target.closest('a')
    if (entry) {
      this.selectItem(entry.item)
    }
  }

  selectItem(item) {
    if (!item || !item.option) return
    Helpers.emit(this.element, 'suggestions:selected', item.option)
  }

  update(forcedRedraw) {
    // No entries, no problem
    toggle(this.noEntriesTarget, this.items.length === 0)

    // VIRTUAL SCROLL BABY
    this.listTarget.style.height = `${this.items.length * this.itemHeightValue}px`
    this.listContainerTarget.style.maxHeight = `${(this.numVisibleItemsValue) * this.itemHeightValue}px`

    const { scrollTop, clientHeight } = this.listContainerTarget
    const visibleItemStart = Math.max(0, Math.floor(scrollTop / this.itemHeightValue) - this.numBufferItemsValue)
    const visibleItemEnd = Math.min(this.items.length, Math.ceil((scrollTop + clientHeight) / this.itemHeightValue) + this.numBufferItemsValue)

    // update only if view changed
    if (visibleItemStart !== this.visibleItemStart || visibleItemEnd !== this.visibleItemEnd || forcedRedraw) {
      this.visibleItemStart = visibleItemStart
      this.visibleItemEnd = visibleItemEnd

      this.viewportTarget.style.top = `${this.visibleItemStart * this.itemHeightValue}px`

      this.entryTargets.forEach((entry, i) => {
        const idx = i + this.visibleItemStart
        entry.style.display = (idx < this.items.length) ? 'block' : 'none'
        entry.style.height = `${this.itemHeightValue}px`

        if (idx < this.items.length) {
          const item = this.items[idx]

          entry.item = item
          entry.dataset.type = item.type
          if(item.group) {
            entry.dataset.group = item.group
          } else {
            delete entry.dataset.group
          }

          const labelNode = entry.querySelector('[class*="__label"]')
          const detailsNode = entry.querySelector('[class*="__details"]')
          const iconNode = entry.querySelector('[class*="__icon"]')

          this.setHighlightedText(labelNode, item.label)
          this.setHighlightedText(detailsNode, item.details)

          toggle(detailsNode, !!detailsNode.textContent)

          iconNode.innerHTML = item.icon ? item.icon : ''
        }
      })
    }

    // selection
    this.entryTargets.forEach((entry, i) => {
      if (this.selectedValues && this.selectedValues.some(value => value == entry.item?.value)) {
        entry.dataset.selected = 1
      } else {
        delete entry.dataset.selected
      }
    })

    // highlighting
    this.entryTargets.forEach((entry, i) => {
      if (this.highlightedValue && this.highlightedValue == entry.item?.value) {
        entry.dataset.highlighted = 1
      } else {
        delete entry.dataset.highlighted
      }
    })
  }

  setHighlightedText(node, label) {
    // clear
    node.innerHTML = ''
    node.title = label

    // add parts
    const parts = label ? String(label).split(this.filterRegex) : []

    parts.forEach((part, i) => {
      if (this.hasFilter && this.filterRegex.test(part)) {
        const highlight = document.createElement('em')
        highlight.appendChild(document.createTextNode(part))
        node.appendChild(highlight)
      } else {
        node.appendChild(document.createTextNode(part))
      }
    })
  }

  onScroll() {
    this.update()
  }

  scrollItemIntoView(item) {
    const index = this.items.indexOf(item)
    const container = this.listContainerTarget
    const v = this.visibleContainerPos(container)
    const e = { top: index * this.itemHeightValue, bottom: (index + 1) * this.itemHeightValue }

    if (e.bottom > v.bottom) {
      const offset = (e.bottom - v.height) > 0 ? (e.bottom - v.height) : 0
      container.scrollTop = offset
    } else if (e.top < v.top) {
      container.scrollTop = e.top
    }
  }

  visibleContainerPos(container) {
    const height = parseInt(window.getComputedStyle(container).height, 10)
    return {
      top: container.scrollTop,
      bottom: container.scrollTop + height,
      height: height,
    }
  }

  visibleEntryPos(entry) {
    return {
      top: entry.offsetTop,
      bottom: entry.offsetTop + entry.offsetHeight,
      height: entry.offsetHeight,
    }
  }

  onMouseMove(event) {
    const entry = event.target.closest('a')
		if (entry) {
      const container = this.viewportTarget
      const v = this.visibleContainerPos(container)
      const e = this.visibleEntryPos(entry)

      const hiddenPixels = Math.max(e.bottom - v.bottom, v.top - e.top, 0)
      const hiddenRatio = hiddenPixels / e.height

      // Make sure we only select if the entry is partially visible
      // Otherwise jumpy (update causes scrollEntryIntoView which causes potential new highlight)
      if (hiddenRatio < 0.75 && entry.item?.value) {
        this.highlightValue(entry.item?.value)
      }
    }
  }

  onSearchChanged(event) {
    this.setFilter(this.searchInputTarget.value)
  }

  handleKey(key) {
    switch(key) {
      case 'Enter':
        this.selectItem(this.highlightedItem)
        return true

      case 'Up': // IE/Edge
      case 'ArrowUp':
        this.moveHighlight(-1)
        return true

      case 'Down': // IE/Edge
      case 'ArrowDown':
        this.moveHighlight(1)
        return true
    }

    return false
  }

  moveHighlight(delta) {
    let index = this.items.indexOf(this.highlightedItem) + delta
    while (index >= 0 && index < this.items.length && !this.items[index].value) {
      index += delta
    }

    if (index >= 0 && index < this.items.length) {
      this.highlightValue(this.items[index].value)
    }
  }

  onKeyPress(event) {
    if(this.handleKey(event.key)) {
      event.preventDefault()
    }
  }

}
