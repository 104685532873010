.c-page-title {
  padding: 2.5em 0 3em;
  text-align: center;
  margin: 0 auto;
  position: relative;
  width: 100%;
}@media print {.c-page-title {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 2rem;
  }.c-page-title h1 {
    margin-top: 1rem;
  }
}

  @media (max-width: 767px) {.c-page-title {
    padding: 1rem;
  }
}

  .c-page-title__back {
    margin: 0 0.75rem;
    position: absolute;
    left: 0;
    line-height: 1;
    top: 2em;
    color: #27272a;
    font-size: 2.5em;
  }

  @media (max-width: 767px) {.c-page-title__back {
    top: 2.5rem;
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

  .c-page-title__back:hover,
    .c-page-title__back:visited,
    .c-page-title__back:active {
      color: #27272a;
    }

  @media (max-width: 767px) {.c-page-title--with-back {
    padding-left: 2rem;
  }
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFwcC9jb21wb25lbnRzL3BhZ2VfdGl0bGVfY29tcG9uZW50LmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLG9CQUFvQjtFQUNwQixrQkFBa0I7RUFDbEIsY0FBYztFQUNkLGtCQUFrQjtFQUNsQixXQUFXO0FBaUNiLENBL0JFLGNBQUE7SUFBQSxnQkFBdUM7SUFBdkMsaUJBQXVDO0lBQXZDLGVBQXVDO0lBQXZDO0VBQXVDLENBR3JDO0lBQUE7RUFBaUI7QUFIb0I7O0VBTXZDLDJCQUFBO0lBQUE7RUFBYTtBQUFBOztFQUViO0lBRUUsaUJBQWlCO0lBQ2pCLGtCQUFrQjtJQUNsQixPQUFPO0lBQ1AsY0FBYztJQUNkLFFBQVE7SUFDUixjQUErQjtJQUMvQixnQkFBZ0I7RUFVbEI7O0VBUkUsMkJBQUE7SUFBQSxXQUFnQjtJQUNoQixtQkFBa0I7SUFBbEI7RUFEZ0I7QUFBQTs7RUFHaEI7OztNQUdFLGNBQStCO0lBQ2pDOztFQUlBLDJCQUFBO0lBQUE7RUFBYztBQUFBIiwiZmlsZSI6ImFwcC9jb21wb25lbnRzL3BhZ2VfdGl0bGVfY29tcG9uZW50LmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jLXBhZ2UtdGl0bGUge1xuICBwYWRkaW5nOiAyLjVlbSAwIDNlbTtcbiAgdGV4dC1hbGlnbjogY2VudGVyO1xuICBtYXJnaW46IDAgYXV0bztcbiAgcG9zaXRpb246IHJlbGF0aXZlO1xuICB3aWR0aDogMTAwJTtcblxuICBAYXBwbHkgcHJpbnQ6bXQtMCBwcmludDpteC0wIHByaW50Om1iLTg7XG5cbiAgaDEge1xuICAgIEBhcHBseSBwcmludDptdC00O1xuICB9XG5cbiAgQGFwcGx5IHNtOnAtNDtcblxuICAmX19iYWNrIHtcbiAgICBmb250LXNpemU6IDAuOWVtO1xuICAgIG1hcmdpbjogMCAwLjc1cmVtO1xuICAgIHBvc2l0aW9uOiBhYnNvbHV0ZTtcbiAgICBsZWZ0OiAwO1xuICAgIGxpbmUtaGVpZ2h0OiAxO1xuICAgIHRvcDogMmVtO1xuICAgIGNvbG9yOiB0aGVtZSgnY29sb3JzLmdyYXkuODAwJyk7XG4gICAgZm9udC1zaXplOiAyLjVlbTtcblxuICAgIEBhcHBseSBzbTp0b3AtMTA7XG4gICAgQGFwcGx5IHNtOnRleHQtM3hsO1xuXG4gICAgJjpob3ZlcixcbiAgICAmOnZpc2l0ZWQsXG4gICAgJjphY3RpdmUge1xuICAgICAgY29sb3I6IHRoZW1lKCdjb2xvcnMuZ3JheS44MDAnKTtcbiAgICB9XG4gIH1cblxuICAmLS13aXRoLWJhY2sge1xuICAgIEBhcHBseSBzbTpwbC04O1xuICB9XG59XG4iXX0= */