import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'valueField',
    'displayField',
  ]

  connect() {
    const value = this.valueFieldTarget.value
    this.displayFieldTarget.value = value && this.secondsToDuration(value)
  }

  onChange() {
    const input = this.displayFieldTarget.value
    const value = this.durationToSeconds(input)
    const display = (value === null ? '' : this.secondsToDuration(value))

    this.valueFieldTarget.value = value
    this.displayFieldTarget.value = display
  }

  onFocus() {
    // setTimeout for Safari issue (full select, followed by deselect + cursor at end)
    setTimeout(() => this.displayFieldTarget.select(), 0)
  }

  secondsToDuration(seconds) {
    if (isNaN(parseFloat(seconds)))
      return null

    if (this.durationFormat === 'decimal') {
      const hours = seconds / 3600.0
      return hours.toFixed(2)
    } else {
      const prefix = seconds < 0 ? '-' : ''

      // use absolute value here since we subtract to get minutes
      const absoluteSeconds = Math.abs(seconds)
      const hours = Math.floor(absoluteSeconds / 3600)
      const minutes = Math.floor((absoluteSeconds - hours * 3600) / 60)

      return `${prefix}${hours}:${minutes < 10 ? '0' : ''}${minutes}`
    }
  }

  durationToSeconds(duration) {
    if (!duration) return null;

    if (duration.includes(':')) {
      const durationArray = duration.split(':')

      const hours = parseInt(durationArray[0], 10) || 0
      const minutes = parseInt(durationArray[1], 10) || 0

      const multi = duration.includes('-') ? -1 : 1
      const absoluteSeconds = Math.abs(hours) * 3600 + Math.abs(minutes) * 60

      return multi * absoluteSeconds
    } else {
      const hours = parseFloat(duration) || 0
      return Math.floor(hours * 3600.0)
    }
  }

  get durationFormat() {
    const el = this.element.closest('[data-duration-format]')
    return el && el.dataset.durationFormat
  }
}
