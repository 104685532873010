import { toggle } from './visibility'
import { FetchRequest } from '@rails/request.js'

export class Helpers {
  static deobfuscateAttributesOnPage = () => {
    document.querySelectorAll('[data-obfuscated-attributes]').forEach(el => {
      const attributes = JSON.parse(atob(el.getAttribute('data-obfuscated-attributes')))

      // assign deobfuscated attributes
      Object.keys(attributes).forEach(key => {
        el[key] = attributes[key]
      })
    })
  }

  static clarifyEmailAddresses = (defaultDomain = 'hakuna.ch') => {
    const links = document.getElementsByClassName('obfuscated-email-address')

    Array.prototype.forEach.call(links, link => {
      const recipient = atob(link.getAttribute('data-recipient'))
      const domain = link.getAttribute('data-domain') ? atob(link.getAttribute('data-domain')) : defaultDomain
      const address = recipient + atob('QA==') + domain

      link.href = `mailto:${address}`

      if (link.textContent.length == 0) {
        link.textContent = address
      }
    })
  }

  static pollAndForward = () => {
    const link = document.querySelector('a[data-poll-and-forward]')

    if (!link) {
      return
    }

    const targetUrl = link.href
    const pollUrl = link.getAttribute('data-poll-url')
    ;(function poll() {
      setTimeout(function() {
        Helpers.fetch('get', pollUrl).then(response => {
          if (response.ok) {
            response.json.then(json => {
              if (json.done == true) {
                window.location.href = targetUrl
              } else {
                poll()
              }
            })
          }
        })
      }, 1000)
    })()
  }

  static loadScript = (src, done) => {
    const js = document.createElement('script')
    js.src = src
    js.onload = function() {
      done()
    }
    js.onerror = function() {
      done(new Error('Failed to load script ' + src))
    }
    document.head.appendChild(js)
  }

  static escapeRegExp = string => {
    // Welcome to stone age
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
    // https://github.com/harvesthq/chosen/blob/be0a298f528ec59ce97889eaeeeb47a2dca9ca79/coffee/lib/abstract-chosen.coffee#L168
    return string.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")
  }

  static deviceHasSmallScreen() {
    const screenWidth = window.screen.availWidth

    if (!screenWidth) {
      return false
    }
    return screenWidth <= 850
  }

  static deviceHasNativeFormControls() {
    if (/HakunaApp|android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      return true
    }
    return false
  }

  // submit using cmd+enter/ctrl+enter
  static checkKeyDownForFormSubmitIntent(event) {
    if (event.key === 'Enter' && (event.metaKey || event.ctrlKey)) {
      const form = event.target.closest('form')
      const submit = form && form.querySelector('input[type="submit"], button')

      // always submit via button as `form.submit()` does not fire any events
      if (submit) {
        submit.focus()
        submit.click()
      }

      event.preventDefault()
      event.stopImmediatePropagation()
    }
  }

  static emit(element, eventName, eventData = null) {
    const event = new CustomEvent(eventName, {
      bubbles: true,
      detail: eventData,
    })

    // setTimeout to make sure stimulus dispatcher is running
    // otherwise events emitted in connect() are not handled
    // cf. https://github.com/stimulusjs/stimulus/issues/222#issuecomment-456192679
    setTimeout(() => element.dispatchEvent(event), 0)
  }

  // https://stackoverflow.com/a/53048030
  static objectToQueryString(params = {}, prefix) {
    const query = Object.keys(params).map((k) => {
      let key = k;
      let value = params[key];

      if (!value && (value === null || value === undefined || isNaN(value))) {
        value = '';
      }

      switch (params.constructor) {
        case Array:
          key = `${prefix}[]`;
          break;
        case Object:
          key = (prefix ? `${prefix}[${key}]` : key);
          break;
      }

      if (typeof value === 'object') {
        return Helpers.objectToQueryString(value, key)
      }

      return `${key}=${encodeURIComponent(value)}`
    })

    return query.join('&')
  }

  static getFormDataForXHR(form) {
    const params = new FormData(form)
    params.delete('_method')
    params.delete('utf8')
    params.delete('authenticity_token')
    return params
  }

  static visit(url) {
    if (window.Turbo) {
      window.Turbo.visit(url)
    } else {
      window.location.href = url
    }
  }

  static reload() {
    Helpers.visit(window.location.href)
  }

  static disableFields(element, disabled) {
    // Element itself
    if (element.matches('input, select, textarea')) {
      element.disabled = disabled
    }

    if (element.matches('.c-select')) {
      element.disabled = disabled
      Helpers.emit(element, 'select:update!')
    }

    // Children
    element.querySelectorAll('input, select, textarea').forEach(input => {
      input.disabled = disabled
    })

    element.querySelectorAll('.c-select').forEach(select => {
      select.disabled = disabled
      Helpers.emit(select, 'select:update!')
    })
  }

  static toggleVisibilityAndState(element, enabled) {
    toggle(element, enabled)
    this.disableFields(element, !enabled)
  }

  // Custom ajax method around Rails.ajax to add our global error handling.
  static ajax(options) {
    throw('Rails.ajax is not available anymore.')
  }

  static fetch(method, url, options = {}) {
    return new FetchRequest(method, url, options)
      .perform()
      .then(response => {
        if(!response.ok) {
          Helpers.emit(document, 'fetch:error', response.response)
        }

        return response
      })
      .catch(error => {
        // request.js has redirected to the login page, so we don't need to raise an error
        if (typeof error === 'string' && error.endsWith('/login')) {
          // return a never resolving promise to signal that the request was aborted
          // subsequent .then() calls will not be executed
          return new Promise(() => {})
        }
        throw error
      })
  }

  // Make sure that requester (i.e. a stimulus controller) only has one pending request
  // And previous requests are aborted gracefully
  // This stops race conditions (form updates etc.)
  static fetchAbortPrevious(requester, method, url, options = {}) {
    const abortController = new AbortController()

    options.signal = abortController.signal

    if (requester.pendingRequestAbortController) {
      requester.pendingRequestAbortController.abort()
    }

    requester.pendingRequestAbortController = abortController

    return new FetchRequest(method, url, options)
      .perform()
      .then(response => {
        requester.pendingRequestAbortController = null

        if (!response.ok) {
          Helpers.emit(document, 'fetch:error', response.response)
        }

        return response
      })
      .catch(error => {
        // the request was aborted or request.js has redirected to the login page, so we don't need to raise an error
        if (error.name === 'AbortError' || (typeof error === 'string' && error.endsWith('/login'))) {
          // return a never resolving promise to signal that the request was aborted
          // subsequent .then() calls will not be executed
          return new Promise(() => {})
        }

        throw error
      }).finally(() => {
        requester.pendingRequestAbortController = null
      })
  }

  // clones elements and makes sure id is unique
  // i.e. checkbox label which specifies unique id in `for` is copied from a template
  static clone(element) {
    const cloned = element.cloneNode(true)

    cloned.querySelectorAll('[id]').forEach(element => {
      const oldId = element.id
      const newId = Helpers.generateUniqueId()

      element.id = newId
      cloned.querySelectorAll(`label[for='${oldId}']`).forEach(label => label.setAttribute('for', newId))
    })

    return cloned
  }


  // auto expand field
  static autoExpand(field) {
    // Fix scrolling issue in large resized areas (causing scroll up and down):
    // https://stackoverflow.com/a/18262927
    var scrollLeft = window.pageXOffset ||
      (document.documentElement || document.body.parentNode || document.body).scrollLeft

    var scrollTop  = window.pageYOffset ||
      (document.documentElement || document.body.parentNode || document.body).scrollTop

    field.style.height = 'auto'
    field.style.height = (field.scrollHeight + 20) + 'px'

    window.scrollTo(scrollLeft, scrollTop)
  }

  // change input value only if there's a change,
  // plus emit a 'change' event so hidden fields etc. can get updated gracefully
  static changeInputValue(input, newValue) {
    if (input.value != newValue) {
      input.value = newValue
      this.emit(input, 'change')
    }
  }

  static generateUniqueId() {
    return Date.now().toString(36) + Math.random().toString(36).substring(2)
  }

  // Outlets selectors are global CSS selectors
  // To use inner outlets, we use this helper in connect()
  // This ensures that multiple components with nested outlets can be safely used + cloned
  static connectTargetAsOutlet(controller, targetName, outletName) {
    const id = Helpers.generateUniqueId()
    controller[`${targetName}Target`].setAttribute('data-inner-outlet-id', id)
    controller.element.setAttribute(`data-${controller.identifier}-${outletName}-outlet`, `[data-inner-outlet-id="${id}"]`)
  }

  // new Date('2024-12-31') in Canada returns Mon Dec 30 2024 16:00:00 GMT-0800 (Pacific Standard Time)
  // This is because the date is parsed in UTC and then converted to local time
  // We want to get a the date in local time -> Mon Dec 31 2024 00:00:00 GMT-0800 (Pacific Standard Time)
  static dateFromISO(isoDateString) {
    if (!isoDateString) return null

    // If dateString matches YYYY-MM-DD format
    const [year, month, day] = isoDateString.split('-').map(Number)
    return new Date(year, month - 1, day)
  }

  static dateToISO(date) {
    if (!date) return null

    const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
    return utcDate.toISOString().split('T')[0]
  }
}
