import { Controller } from '@hotwired/stimulus'
import currency from 'currency.js'

export default class extends Controller {
  static targets = [
    'valueField',
    'displayField',
  ]

  connect() {
    this.convert = value => {
      // if we use inputmode `decimal` (e.g. expense form),
      // iOS offers dot or comma on keyboard (depending on system settings)
      // we cannot determine which one is offered (it's not based on browser locale)
      // so make sure we sanitize the input and treat both dot and comma as decimal separators
      const sanitized = value
        .replace(/[.,]/g, this.element.dataset.separator)
        .replace(/[^0-9.-]/g, '')

      return currency(sanitized, {
        symbol: '', // never show symbol
        decimal: this.element.dataset.separator,
        separator: this.element.dataset.delimiter,
        precision: this.element.dataset.precision,
      })
    }

    const value = this.valueFieldTarget.value
    this.displayFieldTarget.value = value && this.convert(value).format()
  }

  onChange() {
    const value = this.displayFieldTarget.value
    this.displayFieldTarget.value = value && this.convert(value).format()
    this.valueFieldTarget.value = value && this.convert(value).value
  }

  onFocus() {
    this.displayFieldTarget.select()
  }
}
