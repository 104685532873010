import { Controller } from '@hotwired/stimulus'
import { Helpers } from '../../../../utils/helpers'

export default class extends Controller {
  static targets = [
    'minDate',
    'maxDate',
    'dateMustBeWithinRange',
    'dateMustBeAfterRange',
  ]

  connect() {
    this.minDateTarget.addEventListener('date:set', (event) => this.setMinDate(event.detail))
    this.maxDateTarget.addEventListener('date:set', (event) => this.setMaxDate(event.detail))
  }

  disconnect() {
  }

  setMinDate(date) {
    // setting min sets the minimum on max
    if (date) {
      this.maxDateTarget.dataset.datePickerMinimumDate = date
    } else {
      delete this.maxDateTarget.dataset.datePickerMinimumDate
    }
    Helpers.emit(this.maxDateTarget, 'date:update!')

    this.minDate = date
    this.updateFieldsRestrictedByRange()
  }

  setMaxDate(date) {
    // setting max date does nothing for the minimum (minimum has 'free range')

    // additional dates might be restricted by this date range min/max
    this.maxDate = date

    this.updateFieldsRestrictedByRange()
  }

  updateFieldsRestrictedByRange() {
    this.dateMustBeWithinRangeTargets.forEach(dateField => {
      if (this.minDate) {
        dateField.setAttribute('data-date-picker-minimum-date', this.minDate)
      } else {
        dateField.removeAttribute('data-date-picker-minimum-date')
      }

      if (this.maxDate) {
        dateField.setAttribute('data-date-picker-maximum-date', this.maxDate)
      } else {
        dateField.removeAttribute('data-date-picker-maximum-date')
      }

      Helpers.emit(dateField, 'date:update!')
    })

    this.dateMustBeAfterRangeTargets.forEach(dateField => {
      if (this.minDate || this.maxDate) {
        dateField.setAttribute('data-date-picker-minimum-date', this.maxDate || this.minDate)
      } else {
        dateField.removeAttribute('data-date-picker-minimum-date')
      }

      Helpers.emit(dateField, 'date:update!')
    })
  }
}
