import { Controller } from '@hotwired/stimulus'
import { Helpers } from 'pistachio'

export default class extends Controller {
  static targets = [
    'form',
    'calculation',
  ]

  connect() {
    this.update()
  }

  update() {
    this.refreshPrice()
  }

  refreshPrice() {
    const url = this.data.get('url')
    const params = Helpers.getFormDataForXHR(this.formTarget)

    Helpers.fetch('post', url, { body: params }).then(response => {
      if (response.ok) {
        response.html.then(html => {
          this.calculationTarget.innerHTML = html
        })
      }
    })
  }
}
