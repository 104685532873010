import { Controller } from '@hotwired/stimulus'
import { toggle, isVisible } from '../../../utils/visibility'

export default class extends Controller {
  static targets = [
    'target',

    'showWhenExpanded',
    'showWhenCollapsed',

    'disabledWhenExpanded',
    'disabledWhenCollapsed',

  ]

  connect() {
    this.element.addEventListener('toggle', () => this.toggle())
    this.update()
  }

  toggle() {
    // Make sure we toggle first hidden elements, then visible elements
    // Otherwise scroll position can be jumpy because page height gets temporarily reduced (between the individual toggles)
    this.targetTargets
      .sort((t1, t2) => isVisible(t1) - isVisible(t2))
      .forEach(target => toggle(target))

    this.update()
  }

  update() {
    if (!this.hasTargetTarget)
      return

    const isTargetVisible = isVisible(this.targetTargets[0])

    this.showWhenExpandedTargets.forEach(whenExpanded => toggle(whenExpanded, isTargetVisible))
    this.showWhenCollapsedTargets.forEach(whenCollapsed => toggle(whenCollapsed, !isTargetVisible))

    this.disabledWhenExpandedTargets.forEach(whenExpanded => whenExpanded.disabled = isTargetVisible)
    this.disabledWhenCollapsedTargets.forEach(whenCollapsed => whenCollapsed.disabled = !isTargetVisible)
  }
}
