import { Controller } from '@hotwired/stimulus'
import { toggle } from 'pistachio'

export default class extends Controller {
  static targets = [
    'step',

    'previousButton',
    'nextButton',
    'finishButton',

    'progressStep',
  ]

  connect() {
    this.currentStep = 0

    if (this.stepWithErrors) {
      this.setStep(this.stepWithErrors)
    }

    this.update()
  }

  onPreviousStep(event) {
    this.setStep(Math.max(this.firstStep, this.currentStep - 1))

    event.preventDefault() // never submit
    return false
  }

  onNextStep(event) {
    this.setStep(Math.min(this.lastStep, this.currentStep + 1))

    event.preventDefault() // never submit
    return false
  }

  onPopState(event) {
    if (event.state && event.state.step !== undefined) {
      this.setStep(event.state.step, false)
    } else {
      this.setStep(0, false)
    }
  }

  setStep(step, pushState = true) {
    if (step < this.currentStep) {
      // Simply go backward, we do not care about client-side validation
      this.currentStep = step
    } else if (step > this.currentStep) {
      // Go forward, we need to validate the current step before moving on
      while (this.currentStep < step && this.validateCurrentStep(this.currentStep)) {
        this.currentStep++
      }
    }

    if (pushState) {
      history.pushState({ step: this.currentStep }, '')
    }

    this.update()
  }

  onProgressStepClick(event) {
    const step = event.target.closest('[data-step]').dataset.step
    this.setStep(step)
  }

  update() {
    this.stepTargets.forEach((step, index) => {
      toggle(step, index == this.currentStep)
    })

    toggle(this.previousButtonTarget, this.currentStep != this.firstStep)
    toggle(this.nextButtonTarget, this.currentStep != this.lastStep)

    toggle(this.finishButtonTarget, this.currentStep == this.lastStep)

    // make sure enter key doesn't submit the form if we're not on the last step
    this.finishButtonTarget.disabled = this.currentStep !== this.lastStep

    this.progressStepTargets.forEach((progressStep, index) => {
      const isActive = progressStep.dataset.step == this.currentStep
      const isCompleted = progressStep.dataset.step < this.currentStep
      const status = isCompleted ? 'completed' : (isActive ? 'active' : 'inactive')

      progressStep.querySelectorAll('[data-status]').forEach(el => toggle(el, el.dataset.status == status))
    })
  }

  validateCurrentStep() {
    for (let input of this.stepTargets[this.currentStep].querySelectorAll('input, textarea, select')) {
      if (!input.reportValidity()) {
        return false
      }
    }

    return true
  }

  get firstStep() {
    return 0
  }

  get lastStep() {
    return this.stepTargets.length - 1
  }

  get stepWithErrors() {
    for (const [index, step] of this.stepTargets.entries()) {
      if(step.querySelectorAll('.invalid').length > 0) {
        return index
      }
    }
    return false
  }

  get hasStepWithErrors() {
    return this.stepWithErrors !== false
  }

}
