import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['table']

  connect() {
    this.sortColumnIndex = undefined
    this.sortDirection = undefined
  }

  onSort(event) {
    const index = this.headers.indexOf(event.target.closest('th'))

    if (this.sortColumnIndex === index) {
      // same column, flip search order
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc'
    }
    else {
      // new column, reset search order
      this.sortDirection = 'desc'
    }

    this.sortColumnIndex = index
    this.update()
  }

  update() {
    // reset th sort attributes
    this.headers.forEach((th, i) => {
      th.dataset.sort = this.sortColumnIndex == i ? this.sortDirection : ''
    })

    // sort table
    const rows = [...this.tbody.rows]
    const reverse = this.sortDirection === 'asc'

    rows.sort((a, b) => {
      let x = this.valueForSort((reverse ? a : b).cells[this.sortColumnIndex])
      let y = this.valueForSort((reverse ? b : a).cells[this.sortColumnIndex])
      return isNaN(x - y) ? x.localeCompare(y) : x - y
    })

    const newTbody = this.tbody.cloneNode()
    rows.forEach(row => newTbody.appendChild(row))
    this.tableTarget.replaceChild(newTbody, this.tbody)
  }

  get headers() {
    return [...this.tableTarget.tHead.rows[0].cells]
  }

  get tbody() {
    return this.tableTarget.tBodies[0]
  }

  valueForSort(cell) {
    // either explicit value via data attribute or cell text as fallback
    const value = cell.querySelector('[data-sort-value]')
    return value ? value.dataset.sortValue : cell.innerText
  }
}
