.c-button-group {
  display: inline-flex;
}

  .c-button-group > .c-button,
  .c-button-group > form {
    margin-left: 0 !important;
  }

  .c-button-group > :first-child,
  .c-button-group > :first-child > .c-button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .c-button-group > :last-child,
  .c-button-group > :last-child > .c-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .c-button-group > :not(:first-child):not(:last-child) {
    border-radius: 0;
  }

  .c-button-group > *:not(:last-child),
  .c-button-group > *:not(:last-child) > form > .c-button {
    margin-right: -1px;
  }

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uL3Bpc3RhY2hpby9hcHAvY29tcG9uZW50cy9waXN0YWNoaW8vYnV0dG9uX2dyb3VwX2NvbXBvbmVudC5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxvQkFBb0I7QUEyQnRCOztFQXpCRTs7SUFFRSx5QkFBeUI7RUFDM0I7O0VBRUE7O0lBRUUsMEJBQTBCO0lBQzFCLDZCQUE2QjtFQUMvQjs7RUFFQTs7SUFFRSx5QkFBeUI7SUFDekIsNEJBQTRCO0VBQzlCOztFQUVBO0lBQ0UsZ0JBQWdCO0VBQ2xCOztFQUVBOztJQUVFLGtCQUFrQjtFQUNwQiIsImZpbGUiOiIuLi9waXN0YWNoaW8vYXBwL2NvbXBvbmVudHMvcGlzdGFjaGlvL2J1dHRvbl9ncm91cF9jb21wb25lbnQuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmMtYnV0dG9uLWdyb3VwIHtcbiAgZGlzcGxheTogaW5saW5lLWZsZXg7XG5cbiAgPiAuYy1idXR0b24sXG4gID4gZm9ybSB7XG4gICAgbWFyZ2luLWxlZnQ6IDAgIWltcG9ydGFudDtcbiAgfVxuXG4gID4gOmZpcnN0LWNoaWxkLFxuICA+IDpmaXJzdC1jaGlsZCA+IC5jLWJ1dHRvbiB7XG4gICAgYm9yZGVyLXRvcC1yaWdodC1yYWRpdXM6IDA7XG4gICAgYm9yZGVyLWJvdHRvbS1yaWdodC1yYWRpdXM6IDA7XG4gIH1cblxuICA+IDpsYXN0LWNoaWxkLFxuICA+IDpsYXN0LWNoaWxkID4gLmMtYnV0dG9uIHtcbiAgICBib3JkZXItdG9wLWxlZnQtcmFkaXVzOiAwO1xuICAgIGJvcmRlci1ib3R0b20tbGVmdC1yYWRpdXM6IDA7XG4gIH1cblxuICA+IDpub3QoOmZpcnN0LWNoaWxkKTpub3QoOmxhc3QtY2hpbGQpIHtcbiAgICBib3JkZXItcmFkaXVzOiAwO1xuICB9XG5cbiAgPiAqOm5vdCg6bGFzdC1jaGlsZCksXG4gID4gKjpub3QoOmxhc3QtY2hpbGQpID4gZm9ybSA+IC5jLWJ1dHRvbiB7XG4gICAgbWFyZ2luLXJpZ2h0OiAtMXB4O1xuICB9XG59XG4iXX0= */