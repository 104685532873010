import { Controller } from '@hotwired/stimulus'
import { Helpers } from 'pistachio'

export default class extends Controller {

  connect() {
    this.changeHandler = this.onChange.bind(this)
    this.element.addEventListener('change', this.changeHandler)
    this.preview()
  }

  disconnect() {
    this.element.removeEventListener('change', this.changeHandler)
  }

  onChange(event) {
    // setTimout to delay in case we hit enter -> submit first, do not preview (race)
    setTimeout(() => this.preview(), 50)
  }

  preview() {
    // in case form is being submitted, skip preview
    // so it does not lead to race condition with overwriting flashes (session data)
    if (this.element.querySelector('input[type="submit"]:disabled')) return

    Helpers.fetchAbortPrevious(this, 'post', this.element.dataset.formPreviewUrl, {
      body: Helpers.getFormDataForXHR(this.element),
      responseKind: 'turbo-stream',
    })
  }
}
