.c-search-field {
  position: relative;
}@media (max-width: 767px) {.c-search-field {
    width: 100%;
  }
}

  .c-search-field__dropdown {
    margin: 4px 0 0 0;
    width: 100%;
    max-width: 350px;
  }

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uL3Bpc3RhY2hpby9hcHAvY29tcG9uZW50cy9waXN0YWNoaW8vc2VhcmNoX2ZpZWxkX2NvbXBvbmVudC5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxrQkFBa0I7QUFTcEIsQ0FQRSwyQkFBQTtJQUFBO0VBQWdCO0FBQUE7O0VBRWhCO0lBQ0UsaUJBQWlCO0lBQ2pCLFdBQVc7SUFDWCxnQkFBZ0I7RUFDbEIiLCJmaWxlIjoiLi4vcGlzdGFjaGlvL2FwcC9jb21wb25lbnRzL3Bpc3RhY2hpby9zZWFyY2hfZmllbGRfY29tcG9uZW50LmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jLXNlYXJjaC1maWVsZCB7XG4gIHBvc2l0aW9uOiByZWxhdGl2ZTtcblxuICBAYXBwbHkgc206dy1mdWxsO1xuXG4gICZfX2Ryb3Bkb3duIHtcbiAgICBtYXJnaW46IDRweCAwIDAgMDtcbiAgICB3aWR0aDogMTAwJTtcbiAgICBtYXgtd2lkdGg6IDM1MHB4O1xuICB9XG59XG4iXX0= */