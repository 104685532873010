import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'label',
    'value',
  ]

  onSelect(event) {
    this.labelTarget.textContent = event.srcElement.dataset.label
    this.valueTarget.value = event.srcElement.dataset.value
  }
}
