import { Controller } from '@hotwired/stimulus'
import { Helpers } from '../../../utils/helpers'

export default class extends Controller {
  connect() {
    this.element.querySelectorAll('textarea').forEach(textarea => Helpers.autoExpand(textarea))
  }

  onSubmitEnd(event) {
    const response = event.detail.fetchResponse

    if (!response) {
      return
    }

    const isFormRemote = this.element.hasAttribute('data-is-remote')
    const isResponseTurboStream = response.contentType.includes('vnd.turbo-stream')
    const isValidationError = response.statusCode === 422

    if (isValidationError && isFormRemote) {
      // Ensure for `remote` forms, the server returns a turbo stream
      // This makes sure we don't forget to add turbo stream templates for remote actions
      if (!isResponseTurboStream) {
        const message = `Remote Form does not return TurboResponse for 422: ${response.location.pathname}`
        console.error(message)

        if (window.Appsignal) {
          window.Appsignal.sendError(new Error(message))
        }
      } else {
        // Ensure TurboStream replacement action(s) contain the specified target
        // This makes sure we have have a matching and unique target id
        response.responseHTML.then((content) => {
          const doc = new DOMParser().parseFromString(content, 'text/html')
          doc.querySelectorAll('turbo-stream[action="replace"]').forEach((turboStream) => {
            const target = turboStream.getAttribute('target')
            const sources = document.querySelectorAll(`#${target}`)
            if (sources.length !== 1) {
              const message = `Document contains ${sources.length} instead of 1 target ${target}: ${response.location.pathname}`
              console.error(message)

              if (window.Appsignal) {
                window.Appsignal.sendError(new Error(message))
              }
            }
          })
        })
      }
    }
  }

  onKeyDown(event) {
    if (event.target.tagName === 'TEXTAREA') {
      this.onTextAreaKeyDown(event)
    }
  }

  // private
  onTextAreaKeyDown(event) {
    const textarea = event.target

    Helpers.checkKeyDownForFormSubmitIntent(event)

    if (!event.defaultPrevented) {
      Helpers.autoExpand(event.target)
    }
  }
}
