import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'input',
  ]

  connect() {
    this.value = this.inputTargets.find(input => input.checked).value
    this.update()
  }

  onSelect(event) {
    this.value = event.target.value
    this.update()
  }

  update() {
    this.inputTargets.forEach(input => {
      input.closest('label').classList.toggle('active', input.value == this.value)
    })
  }
}
