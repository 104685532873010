import { Controller } from '@hotwired/stimulus'
import { toggle } from 'pistachio'
import Fuse from 'fuse.js'

export default class extends Controller {
  static targets = [
    'field',
    'resultList',
    'resultEntryTemplate',
    'iconSearch',
    'iconClear',
  ]

  connect() {
    const articles = JSON.parse(this.element.getAttribute('data-cms--help--search-index-value'))

    this.fuse = new Fuse(articles, {
      keys: [
        {name: 'title', weight: 2},
        {name: 'content', weight: 1},
      ],
      ignoreLocation: true, // otherwise not full string is searched
      ignoreFieldNorm: true, // otherwise title triumphs
      minMatchCharLength: 3,
      includeScore: true, // nice for debug
      // includeMatches: true, // good to see fu for debug
      threshold: 0.1,
    })
  }

  onSearch(event) {
    this.update()
  }

  onClear(event) {
    this.fieldTarget.value = ''
    this.fieldTarget.focus()
    this.update()
  }

  update() {
    // toggle icons
    const showClear = !!this.fieldTarget.value
    toggle(this.iconSearchTarget, !showClear)
    toggle(this.iconClearTarget, showClear)

    // update results
    const results = this.fuse.search(this.fieldTarget.value)

    const list = this.resultListTarget
    list.innerHTML = ''
    list.classList.toggle('hidden', results.length == 0)

    results.forEach(result => {
      const entry = this.resultEntryTemplateTarget.cloneNode(true)
      entry.classList.remove('hidden')

      const a = entry.querySelector('a')
      const divs = entry.querySelectorAll('div')

      const title = divs[0]
      const excerpt = divs[1]

      a.href = result.item.url

      title.innerText = result.item.title
      excerpt.innerText = result.item.excerpt

      list.append(entry)
    })
  }
}
