.c-mini-cta {
  display: flex;
}@media (max-width: 767px) {.c-mini-cta {
    flex-direction: column;
  }
}.c-mini-cta {

  align-items: center;
  border: 1px solid #d4d4d8;
  border-radius: 0.375rem;
  background: #f4f4f5;
  padding: 0.75em 1em;
}

  .c-mini-cta__content__text {
  font-size: 0.75rem;
  line-height: 1rem;
      color: #52525b;
}

  .c-mini-cta__screenshot {
    flex-shrink: 0;
  }

  @media (max-width: 767px) {.c-mini-cta__screenshot {
    order: -9999;
  }
}

  .c-mini-cta__screenshot {
    margin-left: 1em;
  }

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFwcC9jb21wb25lbnRzL21pbmlfY3RhX2NvbXBvbmVudC5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxhQUFhO0FBcUJmLENBcEJFLDJCQUFBO0lBQUE7RUFBa0I7QUFBQSxDQUZwQjs7RUFJRSxtQkFBbUI7RUFDbkIseUJBQTBDO0VBQzFDLHVCQUFpQjtFQUNqQixtQkFBb0M7RUFDcEMsbUJBQW1CO0FBY3JCOztFQVZNO0VBQUEsa0JBQWM7RUFBZCxpQkFBYztNQUNkO0FBRGM7O0VBS2xCO0lBQ0UsY0FBYztFQUdoQjs7RUFGRSwyQkFBQTtJQUFBO0VBQXFCO0FBQUE7O0VBRnZCO0lBR0UsZ0JBQWdCO0VBQ2xCIiwiZmlsZSI6ImFwcC9jb21wb25lbnRzL21pbmlfY3RhX2NvbXBvbmVudC5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuYy1taW5pLWN0YSB7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIEBhcHBseSBzbTpmbGV4LWNvbDtcblxuICBhbGlnbi1pdGVtczogY2VudGVyO1xuICBib3JkZXI6IDFweCBzb2xpZCB0aGVtZSgnY29sb3JzLmdyYXkuMzAwJyk7XG4gIEBhcHBseSByb3VuZGVkLW1kO1xuICBiYWNrZ3JvdW5kOiB0aGVtZSgnY29sb3JzLmdyYXkuMTAwJyk7XG4gIHBhZGRpbmc6IDAuNzVlbSAxZW07XG5cbiAgJl9fY29udGVudCB7XG4gICAgJl9fdGV4dCB7XG4gICAgICBAYXBwbHkgdGV4dC14cztcbiAgICAgIGNvbG9yOiB0aGVtZSgnY29sb3JzLmdyYXkuNjAwJyk7XG4gICAgfVxuICB9XG5cbiAgJl9fc2NyZWVuc2hvdCB7XG4gICAgZmxleC1zaHJpbms6IDA7XG4gICAgQGFwcGx5IHNtOm9yZGVyLWZpcnN0O1xuICAgIG1hcmdpbi1sZWZ0OiAxZW07XG4gIH1cbn1cbiJdfQ== */