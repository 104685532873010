import { Helpers } from './helpers'

// Available:
// https://polyfill.io/v3/url-builder
const POLYFILL_REQUEST_LIST = [
  'default',
  'es5',
  'es6',

  // Additional requires
  'NodeList.prototype.forEach',
  'Array.prototype.flat',
]

const FEATURE_CHECK_LIST = {
  'window.Promise': window.Promise,
  'window.fetch': window.fetch,
  'window.Symbol': window.Symbol,
  'NodeList.prototype.forEach': NodeList.prototype.forEach,
  'Array.prototype.flat': Array.prototype.flat,
  'Element.prototype.append': Element.prototype.append
}

const POLYFILL_SERVICE_URL = 'https://polyfill.io/v3/polyfill.min.js?flags=gated&features='

export class Polyfills {

  // https://gist.github.com/bendc/d7f3dbc83d0f65ca0433caf90378cd95
  static supportsES6() {
    try {
      new Function("(a = 0) => a")
      return true
    }
    catch (err) {
      return false
    }
  }

  static browserSupportsAllFeatures() {
    for (const feature in FEATURE_CHECK_LIST) {
      if (!this.isFeatureSupported(FEATURE_CHECK_LIST[feature])) return false
    }

    if(!this.supportsES6()) return false

    return true
  }

  static featureSupportList() {
    let features = {}

    features['userAgent'] = navigator.userAgent
    features['ES6'] = this.supportsES6()

    for (const feature in FEATURE_CHECK_LIST) {
      features[feature] = this.isFeatureSupported(FEATURE_CHECK_LIST[feature])
    }

    return features
  }

  static isFeatureSupported(feature) {
    return typeof(feature) !== 'undefined'
  }

  static checkAndRun(main) {
    if (this.browserSupportsAllFeatures()) {
      console.log('[Polyfills] Browser supports all features, smooth sailing ahead.')
      main()
    } else {
      console.log('[Polyfills] Browser does NOT support all features, request polyfills.')

      const features = POLYFILL_REQUEST_LIST.join(',')
      const url = POLYFILL_SERVICE_URL + escape(features)

      Helpers.loadScript(url, main)
    }
  }

}
