import { Controller } from '@hotwired/stimulus'
import { toggle } from 'pistachio'

export default class extends Controller {
  static targets = ['navigation']

  connect() {

  }

  onToggleNavigation(event) {
    this.element.classList.toggle('is-open')
  }
}
