import { Controller } from '@hotwired/stimulus'
import { toggle } from '../../../../utils/visibility'

export default class extends Controller {
  static targets = [
    'input',
    'visible',
    'hidden',
    'meter',
  ]

  connect() {
    this.update()
  }

  update() {
    this.updateScoreMeter()
  }

  toggleVisibility() {
    const visible = this.inputTarget.type == 'text'

    if (visible) {
      this.inputTarget.type = 'password'
    }
    else {
      this.inputTarget.type = 'text'
    }

    toggle(this.visibleTarget, visible)
    toggle(this.hiddenTarget, !visible)
  }

  updateScoreMeter() {
    if (this.meterTargets.length === 0) return

    const score = this.scorePassword(this.inputTarget.value)

    const defaultColor = 'bg-gray-200'
    const poorColor    = 'bg-red-600'
    const weakColor    = 'bg-orange-500'
    const goodColor    = 'bg-green-500'
    const strongColor  = 'bg-green-500'

    let mark = 0
    let background = defaultColor

    if (score === null) {
      // empty pw
      mark = 0
    } else if (score >= 80) {
      mark = 4
      background = strongColor
    } else if(score >= 50) {
      mark = 3
      background = goodColor
    } else if(score >= 25) {
      mark = 2
      background = weakColor
    }
    else if(score < 25) {
      mark = 1
      background = poorColor
    }

    if (this.inputTarget.value.length === 0) mark = 0

    this.meterTargets.forEach((element, index) => {
      element.classList.remove(poorColor, weakColor, goodColor, strongColor)

      if (index < mark) element.classList.add(background)
    })
  }

  // https://stackoverflow.com/questions/948172/password-strength-meter
  // https://gist.github.com/stgogm/8a5edafa39f4d8d393520e2b40ee72e1
  scorePassword(pwd) {
    let check, ltr, i, l
    let variation = 0
    let letters = {}
    let score = 0

    if (!pwd) return null

    /* Score character variation */
    let variations = {
      lower: /[a-z]/.test(pwd),
      upper: /[A-Z]/.test(pwd),
      nonWords: /\W/.test(pwd),
      digits: /\d/.test(pwd)
    }

    for (check in variations) {
      variation += variations[check] ? 1 : 0
    }

    score += (variation - 1) * 10

    /* Score unique letters until 5 repetitions */
    for (i = 0, l = pwd.length; i < l; i++) {
      ltr = letters[pwd[i]] = (letters[pwd[i]] || 0) + 1
      score += 5 / ltr
    }

    /* Score length (about 8 chars for a safe password) */
    score -= 16 - (pwd.length / 16)

    return parseInt(score)
  }
}
