import { Controller } from '@hotwired/stimulus'
import { toggle } from 'pistachio'
import Fuse from 'fuse.js'

export default class extends Controller {
  static targets = [
    'window',
    'field',
    'resultList',
    'resultEntryTemplate',
    'noResults',
  ]

  connect() {
    const articles = JSON.parse(this.element.getAttribute('data-cms--docs--search-index-value'))

    this.fuse = new Fuse(articles, {
      keys: [
        {name: 'title', weight: 2},
        {name: 'content', weight: 1},
      ],
      ignoreLocation: true, // otherwise not full string is searched
      ignoreFieldNorm: true, // otherwise title triumphs
      minMatchCharLength: 1,
      includeScore: true, // nice for debug
      // includeMatches: true, // good to see fu for debug
      threshold: 0.1,
    })

    this.selectedResultIndex = null
    this.results = []
  }

  onOpen(event) {
    toggle(this.windowTarget, true)
    this.fieldTarget.focus()
  }

  onClose(event) {
    toggle(this.windowTarget, false)
  }

  onKeyUp(event) {
    switch(event.key) {
      case 'Escape':
        this.onClose(event)
        break

      case 'Enter':
        const a = this.resultListTarget.querySelector(`a[data-index="${this.selectedResultIndex}"]`)
        if (a) {
          a.click()
        }
        break

      case 'ArrowUp':
        this.selectedResultIndex = Math.max(0, this.selectedResultIndex - 1)
        break

      case 'ArrowDown':
        this.selectedResultIndex = Math.min(this.results.length - 1, this.selectedResultIndex + 1)
        break

      default:
        this.search()
        break
    }

    this.update()
  }

  onKeyDown(event) {
    // Prevent cursor jumping inside input
    if (event.key == 'ArrowUp' || event.key == 'ArrowDown') {
      event.preventDefault()
    }
  }

  onMouseOver(event) {
    const a = event.target.closest('a')
    if (a) {
      this.selectedResultIndex = a.dataset.index
      this.update()
    }
  }

  search() {
    this.results = this.fuse.search(this.fieldTarget.value)

    const list = this.resultListTarget
    list.innerHTML = ''

    this.results.forEach((result, index) => {
      const entry = this.resultEntryTemplateTarget.cloneNode(true)
      entry.classList.remove('hidden')

      const a = entry.querySelector('a')
      const divs = entry.querySelectorAll('div')

      const title = divs[0]
      const excerpt = divs[1]

      a.href = result.item.url
      a.dataset.index = index

      title.innerText = result.item.title
      excerpt.innerText = result.item.excerpt

      list.append(entry)
    })

    this.selectedResultIndex = 0
    this.update()
  }

  update() {
    // Toggle list
    toggle(this.resultListTarget, this.results.length > 0)
    toggle(this.noResultsTarget, this.results.length == 0)

    // Mark selected result
    this.resultListTarget.querySelectorAll('a').forEach(a => {
      a.classList.remove('bg-gray-100')

      if (a.dataset.index == this.selectedResultIndex) {
        a.classList.add('bg-gray-100')
      }
    })
  }
}
