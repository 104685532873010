import { Controller } from '@hotwired/stimulus'
import { toggle, Helpers } from 'pistachio'

export default class extends Controller {
  static targets = [
    'monthlyButton',
    'yearlyButton',

    'monthlyPrice',
    'yearlyPrice',

    'countdown',
  ]

  static values = {
    plans: Array,
    promotionEndTime: String,
  }

  connect() {
    this.pricing = 'yearly'
    this.update()

    this.countdown()
  }

  showYearlyPrices() {
    this.pricing = 'yearly'
    this.update()
  }

  showMonthlyPrices() {
    this.pricing = 'monthly'
    this.update()
  }

  update() {
    this.monthlyPriceTargets.forEach((el) => toggle(el, this.pricing == 'monthly'))
    this.yearlyPriceTargets.forEach((el) => toggle(el, this.pricing == 'yearly'))

    this.monthlyButtonTarget.classList.toggle('active', this.pricing == 'monthly')
    this.yearlyButtonTarget.classList.toggle('active', this.pricing == 'yearly')
  }

  countdown() {
    if (!this.hasCountdownTarget) return

    const end = new Date(this.promotionEndTimeValue)
    const now = new Date()

    // Calculate the remaining time
    const remainingTime = Math.max(end - now, 0)

    const units = {
      days: Math.floor(remainingTime / (1000 * 60 * 60 * 24)),
      hours: Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((remainingTime % (1000 * 60)) / 1000),
    }

    this.countdownTargets.forEach((el) => {
      el.textContent = units[el.dataset.unit]
    })

    setTimeout(() => this.countdown(), 1000)
  }


}
