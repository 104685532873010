import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
  ]

  connect() {
  }

  toggleEntry(event) {
    const entry = event.target.closest('.c-vacation-calculator-faq__entry')
    entry.classList.toggle('c-vacation-calculator-faq__entry--collapsed')
  }
}
