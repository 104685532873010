import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'numFixedHours',
    'startTime',
    'endTime',
    'numAverageHours',
    'result',
  ]

  connect() {
    this.update()
  }

  update() {
    const mode = document.querySelector('input[name="break[mode]"]:checked').value

    this.numFixedHoursTarget.disabled = !(mode === 'hours')
    this.startTimeTarget.disabled = !(mode === 'times')
    this.endTimeTarget.disabled = !(mode === 'times')
    this.numAverageHoursTarget.disabled = !(mode === 'flexible')

    let result = undefined

    switch(mode) {
      case 'hours':
        const fixMins = this.parseHoursToMinutes(this.numFixedHoursTarget.value)
        result = this.calcBreakForWork(fixMins)
        break;

      case 'times':
        const startMins = this.parseHoursToMinutes(this.startTimeTarget.value,/[:,.]/)
        const endMins = this.parseHoursToMinutes(this.endTimeTarget.value, /[:,.]/)
        const presenceMins = Math.max(endMins - startMins, 0)
        result = this.calcBreakForPresence(presenceMins)
        break;

      case 'flexible':
        const flexMins = this.parseHoursToMinutes(this.numAverageHoursTarget.value)
        result = this.calcBreakForWork(flexMins)
        break;
    }

    // console.log(result)

    if (typeof(result) !== 'undefined' && !isNaN(result)) {
      this.resultTarget.innerText = result
    } else {
      this.resultTarget.innerText = '?'
      console.log('error')
    }
  }

  parseHoursToMinutes(value, sep = /[:]/) {
    if (!value || value === '') return NaN

    if (sep.test(value)) {
      const [h, m] = value.split(sep)
      return parseInt(h) * 60 + parseInt(m)
    } else {
      return parseInt(parseFloat(value) * 60)
    }
  }

  calcBreakForPresence(presenceMinutes) {
    return Math.max(
      this.clamp(presenceMinutes - (5.5 * 60), 0, 15),
      this.clamp(presenceMinutes - (7 * 60), 0, 30),
      this.clamp(presenceMinutes - (9 * 60), 0, 60),
    )
  }

  clamp(value, min, max) {
    return Math.min(Math.max(value, min), max)
  }

  calcBreakForWork(workMinutes) {
    if (workMinutes > 9 * 60)
      return 60

    if (workMinutes > 7 * 60)
      return 30

    if (workMinutes > 5.5 * 60)
      return 15

    return 0
  }
}
