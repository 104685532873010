import { Controller } from '@hotwired/stimulus'
import { Helpers } from '../../../utils/helpers'
import { toggle } from '../../../utils/visibility'

export default class extends Controller {
  static targets = [
    'suggestions',
    'dropdown',
    'value',
    'placeholder',
    'label',
    'deselect',
    'input',
    'icon',
    'list',
  ]

  static outlets = [
    'pistachio--suggestions',
  ]

  connect() {
    Helpers.connectTargetAsOutlet(this, 'suggestions', 'pistachio--suggestions')

    this.isOpen = false
    this.blankLabel = this.element.getAttribute('data-blank-label') || ''
    this.isStateless = this.element.hasAttribute('data-is-stateless')
    this.asButton = this.element.hasAttribute('data-as-button')
    this.options = []

    // Clicking on label should toggle dropdown
    document.querySelectorAll(`label[for="${this.inputId}"]`).forEach(label => {
      label.addEventListener('click', event => this.toggleList())
    })

    this.element.addEventListener('select:load!', event => this.load(event.detail))
    this.element.addEventListener('select:update!', event => this.update())
    this.element.addEventListener('select:focus!', event => this.valueTarget.focus())

    const options = JSON.parse(this.element.dataset.options)
    this.load(options)
  }

  get associatedLabels() {
    return [...document.querySelectorAll(`label[for="${this.inputId}"]`)]
  }

  onDocumentClick(event) {
    // Close on clicking outside
    if (this.isOpen) {
      const isClickOutside = !this.element.contains(event.target) && !this.associatedLabels.some(label => label.contains(event.target))
      if (isClickOutside) {
        this.hideList()
      }
    }
  }

  load(options) {
    // Load options
    this.options = options

    // Populate suggestions list, timeout for better initial loading performance
    setTimeout(() => this.pistachioSuggestionsOutlet.loadOptions(this.options))

    // Check if current value is still valid
    if (!this.isStateless) {
      const selectedOption = this.findOption(this.selectedValue)
      if (!selectedOption) {
        if (this.isBlankAllowed) {
          this.select(null)
        } else {
          this.select(this.options.length > 0 ? this.options[0] : null)
        }
      } else {
        this.select(selectedOption)
      }
    }

    // Update
    this.update()
  }

  onValueClick(event) {
    this.toggleList()
  }

  toggleList() {
    if (this.isOpen) {
      this.hideList()
    } else {
      this.showList()
    }
  }

  showList() {
    if (this.isDisabled)
      return

    // Mark values as selected
    this.pistachioSuggestionsOutlet.markValuesAsSelected([this.selectedValue])

    this.isOpen = true
    this.update()

    // Make sure list is shown before selecting value
    this.pistachioSuggestionsOutlet.highlightValue(this.selectedValue)

    // After update, focus input (if not on mobile)
    this.pistachioSuggestionsOutlet.focus()
  }

  hideList() {
    this.isOpen = false
    this.update()
  }

  onSuggestionsSelected(event) {
    const option = event.detail
    this.select(option)
    this.hideList()
    this.valueTarget.focus()
  }

  onDeselect(event) {
    this.select(null)
  }

  select(option) {
    // disabled?
    if (this.isDisabled)
      return

    Helpers.emit(this.element, 'select:set', option)

    if (this.isStateless)
      return

    const newValue = option ? String(option.value) : '' // make sure we deal with values as strings, otherwise value 0 as first value is not selected (because != '')
    // Only 'change' if value truly changed
    if (this.selectedValue != newValue) {
      // Set new value for input
      this.selectedValue = newValue

      // Update
      this.update()

      // Trigger change event on hidden input, so forms update
      this.inputTarget.dispatchEvent(new Event('change', { bubbles: true }))

      // Trigger custom event
      Helpers.emit(this.element, 'select:changed', option)
    }

    // Mark values as selected
    this.pistachioSuggestionsOutlet.markValuesAsSelected([this.selectedValue])
  }

  get isDisabled() {
    return this.element.disabled === true || this.element.getAttribute('disabled') === '' || this.element.getAttribute('disabled') === 'disabled'
  }

  update() {
    // Label target
    const option = this.findOption(this.selectedValue)

    const hasIcon = !!(option && option.icon)
    this.iconTarget.innerHTML = hasIcon ? option.icon : ''
    toggle(this.iconTarget, hasIcon)

    this.labelTarget.textContent = option ? option.label : this.blankLabel

    this.labelTarget.title = this.labelTarget.textContent

    // Placeholder if label is empty ''
    if (this.hasPlaceholderTarget) {
      const hasLabel = Boolean(this.labelTarget.textContent)
      toggle(this.placeholderTarget, !hasLabel)
      toggle(this.labelTarget, hasLabel)
    }

    // Toggle list if open
    this.element.classList.toggle('open', this.isOpen)

    // Dropdown Enabled?
    this.element.classList.toggle('disabled', this.isDisabled)
    this.valueTarget.setAttribute('tabindex', this.isDisabled ? -1 : 0)
    // Don't submit input target when disabled
    this.inputTarget.disabled = this.isDisabled

    // Deselect x
    if (this.isBlankAllowed) {
      // Show x if value not blank
      toggle(this.deselectTarget, this.hasValueSelected, 'flex')
    }

    // Toggle active style if open and `as_button` is true
    this.valueTarget.classList.toggle('active', this.asButton && this.isOpen)
  }

  onKeyDown(event) {
    switch (event.key) {
      case 'Up': // IE/Edge
      case 'ArrowUp':
      case 'Down': // IE/Edge
      case 'ArrowDown':
      case 'Enter':
        Helpers.checkKeyDownForFormSubmitIntent(event)

        if (!event.defaultPrevented) { // no submit intent
          if (!this.isOpen) {
            this.showList()
          } else {
            this.pistachioSuggestionsOutlet.handleKey(event.key)
          }

          event.preventDefault()
          event.stopImmediatePropagation()
        }
        break;

      case 'Tab':
        if (this.isOpen) {
          this.hideList()
          // do not prevent, let it tab to next input
        }
        break

      case 'Esc':
      case 'Escape':
        if (this.isOpen) {
          this.hideList()
          event.preventDefault()
          event.stopImmediatePropagation() // do not send it to modal
          this.valueTarget.focus()
        }
        break

      case 'Clear':
      case 'Delete':
      case 'Backspace':
        if (!this.isOpen) {
          this.select(null)
          event.preventDefault()
          event.stopImmediatePropagation()
        }
        break

      default:
        const withModifier = event.shiftKey || event.metaKey || event.ctrlKey || event.altKey
        if (!this.isOpen && !withModifier && event.key.length == 1) {
          event.preventDefault()
          this.showList()
          this.pistachioSuggestionsOutlet.setSearchTerm(event.key)
        }
        break
    }
  }

  findOption(value) {
    return this.options.find(option => String(option.value) === String(value))
  }

  get isBlankAllowed() {
    return this.hasDeselectTarget
  }

  get hasValueSelected() {
    return Boolean(this.selectedValue)
  }

  get selectedValue() {
    return this.inputTarget.value
  }

  set selectedValue(value) {
    this.inputTarget.value = value
  }

  get inputId() {
    return this.inputTarget.id
  }
}
