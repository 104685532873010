import { Controller } from '@hotwired/stimulus'
import flatpickr from 'flatpickr/dist/flatpickr'
import labelPlugin from 'flatpickr/dist/plugins/labelPlugin/labelPlugin'
import 'flatpickr/dist/l10n/de'

import { Helpers } from '../../../utils/helpers'

export default class extends Controller {
  DATE_FORMATS = {
    default: 'D, j. M Y',
    short: 'd.m.Y',
  }

  connect() {
    flatpickr(this.element, {
      locale: window.lang || 'de',
      dateFormat: 'Y-m-d', // ISO
      altInput: true,
      altInputClass: this.altInputClass,
      altFormat: this.dateFormat,
      ariaDateFormat: this.dateFormat,
      allowInput: this.allowInput,
      now: new Date(),
      position: this.element.dataset.datePickerPosition || 'below center',
      disableMobile: this.element.dataset.datePickerDisableOnMobile,
      appendTo: this.appendTo,
      plugins: [new labelPlugin()],
      onReady: (selectedDates, dateStr, instance) => {
        this.picker = instance
        this.registerEvents()
        this.update()

        // initial set
        Helpers.emit(this.element, 'date:set', dateStr)
      },
      onChange: (selectedDates, dateStr, instance) => {
        // changed
        Helpers.emit(this.element, 'date:changed', dateStr)
        // as well as set
        Helpers.emit(this.element, 'date:set', dateStr)
      },
    })

    return false
  }

  disconnect() {
    this.picker.destroy()
  }


  registerEvents() {
    // Listen to custom events
    this.element.addEventListener('date:set!', (event) => {
      const date = Helpers.dateFromISO(event.detail)

      // date:set! should always override range limits imposed by minDate/maxDate
      // (i.e.setting both start_end and end_date at the same time should ignore ranges,
      // since the ranges are updated AFTER the new dates have been set, which would clear
      // the new end_date since it does not fall in the old range)
      const minDate = this.picker.config.minDate
      if (date && minDate && date < minDate) {
        this.picker.set('minDate', date)
      }

      const maxDate = this.picker.config.maxDate
      if (date && maxDate && date > maxDate) {
        this.picker.set('maxDate', date)
      }

      this.picker.setDate(date, true) // true => trigger onChange
    })
    this.element.addEventListener('date:toggle!', () => this.toggle())
    this.element.addEventListener('date:update!', () => this.update())
    this.element.addEventListener('date:clear!', () => this.clear())
  }

  update() {
    // check disabled
    this.picker._input.disabled = this.element.disabled

    const selectedDate = Helpers.dateToISO(this.picker.selectedDates[0])

    // check min/max
    const minDate = this.element.dataset.datePickerMinimumDate
    const maxDate = this.element.dataset.datePickerMaximumDate

    if (minDate && selectedDate < minDate) {
      this.picker.setDate(minDate, true) // true => trigger onChange
    }

    if (maxDate && selectedDate > maxDate) {
      this.picker.setDate(maxDate, true) // true => trigger onChange
    }

    this.picker.set('minDate', minDate)
    this.picker.set('maxDate', maxDate)
  }

  toggle() {
    this.picker.toggle()
  }

  clear() {
    this.picker.clear()
  }

  get appendTo() {
    const selector = this.element.dataset.datePickerAppendSelector
    if (!selector) return
    return document.querySelector(selector)
  }

  get dateFormat() {
    const format = this.element.dataset.datePickerFormat || 'default'
    return this.DATE_FORMATS[format]
  }

  get allowInput() {
    return ('allowInput' in this.element.dataset)
  }
}
