import { Application } from '@hotwired/stimulus'

const application = Application.start()

application.handleError = (error, message, detail) => {
  console.warn(error, message, detail)
  if (window.Appsignal) {
    window.Appsignal.sendError(error)
  }
}

export default application
