import { Controller } from '@hotwired/stimulus'
import { Helpers } from '../../../../utils/helpers'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [
    'modal',
    'message',
    'title',
    'ok',
    'cancel',
  ]

  connect() {
    Rails.delegate(this.element, '[data-confirm]', 'click', event => {
      // Get data confirm element (e.g. event.target can be an icon inside a button)
      const element = event.target.closest('[data-confirm]')

      // Check that the element is enabled (e.g. button disabled)
      if (!element.disabled) {
        this.confirm(element)
        Rails.stopEverything(event)
      }
    })
  }

  confirm(el) {
    this.confirmElement = el

    this.messageTarget.innerHTML = el.getAttribute('data-confirm-message')
    this.titleTarget.innerHTML = el.getAttribute('data-confirm-title')

    this.okTarget.innerHTML = el.getAttribute('data-confirm-ok')
    this.okTarget.className = `c-button ${el.getAttribute('data-confirm-ok-class')}`

    this.cancelTarget.innerHTML = el.getAttribute('data-confirm-cancel')
    this.cancelTarget.className = `c-button c-button--secondary ${el.getAttribute('data-confirm-cancel-class')}`

    Helpers.emit(this.modalTarget, 'modal:open!')
  }

  ok() {
    Helpers.emit(this.modalTarget, 'modal:close!')

    if (this.confirmElement) {
      this.confirmElement.removeAttribute('data-confirm')
      this.confirmElement.click()
    }
  }
}
