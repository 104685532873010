import { Controller } from '@hotwired/stimulus'
import { Helpers } from '../../../utils/helpers'
import { toggle, isVisible } from '../../../utils/visibility'

export default class extends Controller {
  static targets = [
    'suggestions',
    'input',
    'hidden',
    'dropdown',
    'list',
    'placeholder',
  ]

  static outlets = [
    'pistachio--suggestions',
  ]

  static values = {
    selectedValues: Array,
    options: Array,
  }

  connect() {
    Helpers.connectTargetAsOutlet(this, 'suggestions', 'pistachio--suggestions')

    this.selectedValues = this.selectedValuesValue || []
    this.placeholder = this.inputTarget.placeholder

    if (this.optionsValue) {
      this.load(this.optionsValue)
    }
    this.element.addEventListener('multi-select:load!', event => this.load(event.detail))


    // Clicking on label should toggle dropdown
    document.querySelectorAll(`label[for="${this.hiddenTarget.id}"]`).forEach(label => {
      label.addEventListener('click', event => this.inputTarget.focus())
    })

    // Whenever the input field is disabled from the outside, we need to update this element to reflect the disabled state
    this.observer = new MutationObserver((mutationList) => {
      mutationList.forEach((mutation) => {
        if (mutation.attributeName === 'disabled') {
          this.update()
        }
      })
    })
    this.observer.observe(this.hiddenTarget, { attributes: true })
  }

  disconnect() {
    this.observer.disconnect()
  }

  load(options) {
    this.options = options
    this.pistachioSuggestionsOutlet.loadOptions(options)
    this.update()
  }

  onMouseDown(event) {
    if (document.activeElement === this.inputTarget) {
      if (!this.isOpen) {
        this.open()
      }
    } else {
      this.inputTarget.focus()
    }

    event.preventDefault()
  }

  onInputFocus(event) {
    this.open()
  }

  onInputBlur(event) {
    this.close()
  }

  toggle(value) {
    const index = this.selectedValues.indexOf(value)
    if (index === -1) {
      Helpers.emit(this.element, 'multi-select:selected', value)
      this.selectedValues.push(value)
    } else {
      Helpers.emit(this.element, 'multi-select:unselected', value)
      this.selectedValues.splice(index, 1)
    }

    this.refreshSuggestions()
    this.update()

    // trigger change event on hidden input, so forms update
    // (do this after update since this will update the hidden inputs)
    this.hiddenTarget.dispatchEvent(new Event('change', { bubbles: true }))
  }

  onSuggestionsSelected(event) {
    this.toggle(event.detail.value)

    // clear field
    this.inputTarget.value = ''

    if (!this.multiModeEnabled) {
      this.close()
    }
  }

  onInputKey(event) {
    this.handleInput()
  }

  onInputChange(event) {
    this.handleInput()
  }

  handleInput() {
    if (this.inputTarget.value != '' && !this.isOpen) {
      this.open()
    }

    this.refreshSuggestions()
    this.update()
  }

  get selectedOptions() {
    return this.selectedValues
      .map(value => this.options.find(o => String(o.value) === String(value)))
      .filter(option => option) // remove selected values which are not in the options
  }

  update() {
    // Update input
    this.inputTarget.placeholder = this.selectedOptions.length === 0 ? this.placeholder : ''

    this.inputTarget.disabled = this.hiddenTarget.disabled
    this.listTarget.classList.toggle('disabled', this.hiddenTarget.disabled)

    // Update focus
    const focus = this.element.contains(document.activeElement)
    if (focus) {
      this.listTarget.dataset.focus = true
    } else {
      delete this.listTarget.dataset.focus
    }

    this.inputTarget.size = Math.max(this.inputTarget.value.length, this.inputTarget.placeholder.length) + 3

    // Update list
    this.listTarget.querySelectorAll('li[data-list-entry="item"]').forEach(item => item.remove())

    this.selectedOptions.reduce((node, option) => {
      const item = document.createElement('li')
      item.dataset.listEntry = 'item'

      const text = document.createElement('span')
      text.textContent = option.label
      text.title = option.label // show full text on hover, in case of truncation
      item.appendChild(text)

      const deselect = document.createElement('a')
      // mousedown instead of click to prevent blur event from firing
      deselect.addEventListener('mousedown', (event) => {
        this.toggle(option.value)
        event.preventDefault()
        event.stopImmediatePropagation()
      })
      item.appendChild(deselect)

      return this.listTarget.insertBefore(item, node).nextElementSibling
    }, this.listTarget.firstChild)

    // Update inputs
    this.hiddenTargets.forEach(input => {
      if (input !== this.hiddenTarget) {
        input.remove()
      }
    })
    this.hiddenTarget.value = null
    if (this.selectedOptions.length > 0) {
      this.hiddenTarget.value = this.selectedOptions[0].value

      // Create additional inputs for each selected value (keep selection order)
      this.selectedOptions.slice(1).reduce((prevInput, option) => {
        const input = prevInput.cloneNode()
        input.value = option.value
        prevInput.parentNode.insertBefore(input, prevInput.nextSibling)
        return input
      }, this.hiddenTarget)
    }
  }

  onInputKeyDown(event) {
    this.multiModeEnabled = (event.metaKey || event.ctrlKey)

    switch(event.key) {
      case 'Up': // IE/Edge
      case 'ArrowUp':
      case 'Down': // IE/Edge
      case 'ArrowDown':
      case 'Enter':
        Helpers.checkKeyDownForFormSubmitIntent(event)

        if (!event.defaultPrevented) {
          if (!this.isOpen) {
            this.open()
          } else {
            this.pistachioSuggestionsOutlet.handleKey(event.key)
          }
          event.preventDefault()
        }
        break;

      case 'Escape':
        this.close()
        event.preventDefault()
        break;

      case 'Backspace':
        if (this.inputTarget.value === '')  {
          this.selectedValues.pop()
          this.close() // close list in case it's open
          this.update()
          event.preventDefault()
        }
        break;
    }
  }

  refreshSuggestions() {
    this.pistachioSuggestionsOutlet.markValuesAsSelected(this.selectedValues)
    this.pistachioSuggestionsOutlet.setFilter(this.inputTarget.value)
  }

  open() {
    toggle(this.dropdownTarget, true)
    this.refreshSuggestions()
    this.update()
  }

  close() {
    toggle(this.dropdownTarget, false)
    this.inputTarget.value = ''
    this.update()
  }

  get isOpen() {
    return isVisible(this.dropdownTarget)
  }

}
