.c-hakuna-breadcrumb {

  display: inline-flex;

  align-items: center;

  position: relative;

  overflow: hidden;

  --tw-bg-opacity: 1;

  background-color: rgb(239 246 255 / var(--tw-bg-opacity));

  font-size: 0.875rem;

  line-height: 1.25rem;

  --tw-text-opacity: 1;

  color: rgb(30 58 138 / var(--tw-text-opacity));

  padding: 5px 5px;

  padding-left: 2.25rem
}

  .c-hakuna-breadcrumb__logo {

  display: inline-flex;

  align-items: center;

  justify-content: center;

  position: absolute;

  left: 0px;

  top: 0px;

  bottom: 0px;

  --tw-bg-opacity: 1;

  background-color: rgb(29 78 216 / var(--tw-bg-opacity));

  width: 1.75rem
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFwcC9jb21wb25lbnRzL2hha3VuYV9icmVhZGNydW1iX2NvbXBvbmVudC5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0U7O0VBQUEsb0JBQWtCOztFQUNsQixtQkFBbUI7O0VBQ25CLGtCQUFlOztFQUNmLGdCQUFzQjs7RUFDdEIsa0JBQWlCOztFQUFqQix5REFBaUI7O0VBQ2pCLG1CQUE0Qjs7RUFBNUIsb0JBQTRCOztFQUE1QixvQkFBNEI7O0VBQTVCLDhDQUE0Qjs7RUFFNUIsZ0JBQWdCOztFQUNoQjtBQVJrQjs7RUFXaEI7O0VBQUEsb0JBQWtCOztFQUNsQixtQkFBbUI7O0VBQ25CLHVCQUFxQjs7RUFDckIsa0JBQWU7O0VBQ2YsU0FBYTs7RUFDYixRQUFZOztFQUNaLFdBQWU7O0VBQ2Ysa0JBQWtCOztFQUFsQix1REFBa0I7O0VBQ2xCO0FBUmtCIiwiZmlsZSI6ImFwcC9jb21wb25lbnRzL2hha3VuYV9icmVhZGNydW1iX2NvbXBvbmVudC5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuYy1oYWt1bmEtYnJlYWRjcnVtYiB7XG4gIEBhcHBseSBpbmxpbmUtZmxleDtcbiAgQGFwcGx5IGl0ZW1zLWNlbnRlcjtcbiAgQGFwcGx5IHJlbGF0aXZlO1xuICBAYXBwbHkgb3ZlcmZsb3ctaGlkZGVuO1xuICBAYXBwbHkgYmctYmx1ZS01MDtcbiAgQGFwcGx5IHRleHQtc20gdGV4dC1ibHVlLTkwMDtcblxuICBwYWRkaW5nOiA1cHggNXB4O1xuICBAYXBwbHkgcGwtOTtcblxuICAmX19sb2dvIHtcbiAgICBAYXBwbHkgaW5saW5lLWZsZXg7XG4gICAgQGFwcGx5IGl0ZW1zLWNlbnRlcjtcbiAgICBAYXBwbHkganVzdGlmeS1jZW50ZXI7XG4gICAgQGFwcGx5IGFic29sdXRlO1xuICAgIEBhcHBseSBsZWZ0LTA7XG4gICAgQGFwcGx5IHRvcC0wO1xuICAgIEBhcHBseSBib3R0b20tMDtcbiAgICBAYXBwbHkgYmctYmx1ZS03MDA7XG4gICAgQGFwcGx5IHctNztcbiAgfVxufVxuIl19 */