import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['tocLink']

  TOC_LINK_CURRENT_CLASS = 'c-guide__toc-link--current'

  connect() {
    this.tocLinkTargets[0].classList.add(this.TOC_LINK_CURRENT_CLASS)
  }

  onScroll() {
    const currentLink = document.querySelector(`.${this.TOC_LINK_CURRENT_CLASS}`)

    this.tocLinkTargets.reverse().some(link => {
      let section = this.findElementByHash(link.hash)

      if (section.getBoundingClientRect().top < 100) {
        currentLink && currentLink.classList.remove(this.TOC_LINK_CURRENT_CLASS)
        link.classList.add(this.TOC_LINK_CURRENT_CLASS)
        return true
      }
    })
  }

  onJumpToSection(event) {
    event.preventDefault()

    const target = this.findElementByHash(event.target.hash)

    target.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })

    window.history.pushState(null, null, event.target.hash)
  }

  findElementByHash(hash) {
    return document.querySelector(`[id="${hash.substring(1)}"]`)
  }
}
