import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'selectedFiles']

  connect() {
    // DataTransfer is used to store the files
    this.dataTransfer = new DataTransfer()
  }

  onAddFiles(event) {
    // If we only allow one file, we clear the `dataTransfer` object to avoid multiple files.
    if(!this.inputTarget.multiple) {
      this.dataTransfer.items.clear()
    }

    for(let file of this.inputTarget.files) {
      if(!this.fileAlreadyAdded(file)) {
        this.dataTransfer.items.add(file)
      }
    }

    this.inputTarget.files = this.dataTransfer.files

    this.update()
  }

  onRemoveFile(event) {
    let index = event.currentTarget.dataset.fileIndex
    this.dataTransfer.items.remove(index)
    this.inputTarget.files = this.dataTransfer.files

    this.update()
  }

  update() {
    this.selectedFilesTarget.innerHTML = ''

    Array.from(this.dataTransfer.files).forEach((file, index) => {
      let html = `
        <div class="flex items-center">
          <div class="inline-block max-w-[12rem] mr-1 truncate">${file.name}</div>
          <a class="cursor-pointer font-medium hover:text-red-500" data-action="click->pistachio--form--file-field#onRemoveFile" data-file-index="${index}">×</a>
        </div>
      `

      this.selectedFilesTarget.insertAdjacentHTML('beforeend', html)
    })
  }

  fileAlreadyAdded(file) {
    return Array.from(this.dataTransfer.files).some(f => f.name === file.name && f.size === file.size && f.lastModified === file.lastModified)
  }
}
