import { Controller } from '@hotwired/stimulus'
import { toggle, isVisible } from 'pistachio'

export default class extends Controller {
  static targets = [
    'publicHolidayTemplate',
    'publicHoliday',
  ]

  connect() {
    this.publicHolidaysByRegion = JSON.parse(this.element.dataset.publicHolidaysByRegion)
    this.initialSelectedPublicHolidays = JSON.parse(this.element.dataset.initialSelectedPublicHolidays)

    this.update()
  }

  onPublicHolidayRegionSet(event) {
    const selectedRegion = event.detail && event.detail.value

    this.clearPublicHolidays()

    if (selectedRegion) {
      this.publicHolidaysByRegion[selectedRegion].forEach(ph => {
        let label = ph.label
        if (!ph.whole_day) {
          label += ' (½)'
        }

        const dateObj = new Date(ph.date)
        const date = `${('0' + dateObj.getDate()).slice(-2)}.${('0' + (dateObj.getMonth()+1)).slice(-2)}`

        let checked = ph.standard

        if (!this.listInitialized) {
          // we changed region, so reset checkbox
          if (this.initialSelectedPublicHolidays) {
            checked = this.initialSelectedPublicHolidays.includes(ph.id)
          }
        }

        this.addPublicHoliday(ph.id, date, label, checked)
      })
    }
    this.listInitialized = true

    this.update()
  }

  clearPublicHolidays() {
    this.publicHolidayTargets.forEach(el => el.remove())
  }

  addPublicHoliday(id, date, label, checked = true) {
    const template = this.publicHolidayTemplateTarget
    const el = template.cloneNode(true)

    el.querySelector('.date').innerText = date
    el.querySelector('.label').innerText = label
    el.classList.remove('hidden')
    el.setAttribute('data-hakuna--work-calculator-target', 'publicHoliday')

    const input = el.querySelector('input[type="checkbox"]')
    input.value = id
    input.disabled = false // allow submit
    input.id = `${id}`
    input.checked = checked

    // connect click on label
    el.querySelectorAll('label').forEach(x => x.htmlFor = input.id)

    template.parentNode.insertBefore(el, template)
  }

  update() {
  }
}
