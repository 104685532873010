.c-vacation-calculator-faq {
  margin-top: 3rem;
}

  .c-vacation-calculator-faq__entry__question {
      color: #1e3a8a;
      font-size: 1.125rem;
      line-height: 1.75rem;
      font-weight: 600;

      display: flex;
      flex-display: row;
      align-items: center;

      cursor: pointer;
    }

  .c-vacation-calculator-faq__entry__question svg {
        margin-right: .3em;
      }

  .c-vacation-calculator-faq__entry .icon-plus { display: none; }

  .c-vacation-calculator-faq__entry .icon-minus { display: inline-block; }

  .c-vacation-calculator-faq__entry--collapsed .icon-plus { display: inline-block; }

  .c-vacation-calculator-faq__entry--collapsed .icon-minus { display: none; }

  .c-vacation-calculator-faq__entry--collapsed .c-vacation-calculator-faq__entry__answer {
        display: none;
      }

  .c-vacation-calculator-faq__entry {

    padding: 1em 0;
    border-bottom: 1px solid #d4d4d8;
}

  .c-vacation-calculator-faq__entry:last-of-type {
      border-bottom: none;
    }

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFwcC9jb21wb25lbnRzL3ZhY2F0aW9uX2NhbGN1bGF0b3IvZmFxX2NvbXBvbmVudC5jc3MiLCI8bm8gc291cmNlPiJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDRTtFQUFBO0FBQVk7O0VBR1Y7TUFDRSxjQUErQjtNQUMvQixtQkFBYztNQUFkLG9CQUFjO01BQ2QsZ0JBQW9COztNQUVwQixhQUFhO01BQ2IsaUJBQWlCO01BQ2pCLG1CQUFtQjs7TUFFbkIsZUFBZTtJQUtqQjs7RUFIRTtRQUNFLGtCQUFrQjtNQUNwQjs7RUFHRiwrQ0FBYSxhQUFhLEVBQUU7O0VBQzVCLGdEQUFjLHFCQUFxQixFQUFFOztFQUduQywwREFBYSxxQkFBcUIsRUFBRTs7RUFDcEMsMkRBQWMsYUFBYSxFQUFFOztFQUU3QjtRQUNFLGFBQWE7TUFDZjs7RUM3Qk47O0lEZ0NJLGNBQWM7SUFDZDtDQ2pDSjs7RURrQ0k7TUFDRSxtQkFBbUI7SUFDckIiLCJmaWxlIjoiYXBwL2NvbXBvbmVudHMvdmFjYXRpb25fY2FsY3VsYXRvci9mYXFfY29tcG9uZW50LmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jLXZhY2F0aW9uLWNhbGN1bGF0b3ItZmFxIHtcbiAgQGFwcGx5IG10LTEyO1xuXG4gICZfX2VudHJ5IHtcbiAgICAmX19xdWVzdGlvbiB7XG4gICAgICBjb2xvcjogdGhlbWUoJ2NvbG9ycy5ibHVlLjkwMCcpO1xuICAgICAgQGFwcGx5IHRleHQtbGc7XG4gICAgICBAYXBwbHkgZm9udC1zZW1pYm9sZDtcblxuICAgICAgZGlzcGxheTogZmxleDtcbiAgICAgIGZsZXgtZGlzcGxheTogcm93O1xuICAgICAgYWxpZ24taXRlbXM6IGNlbnRlcjtcblxuICAgICAgY3Vyc29yOiBwb2ludGVyO1xuXG4gICAgICBzdmcge1xuICAgICAgICBtYXJnaW4tcmlnaHQ6IC4zZW07XG4gICAgICB9XG4gICAgfVxuXG4gICAgLmljb24tcGx1cyB7IGRpc3BsYXk6IG5vbmU7IH1cbiAgICAuaWNvbi1taW51cyB7IGRpc3BsYXk6IGlubGluZS1ibG9jazsgfVxuXG4gICAgJi0tY29sbGFwc2VkIHtcbiAgICAgIC5pY29uLXBsdXMgeyBkaXNwbGF5OiBpbmxpbmUtYmxvY2s7IH1cbiAgICAgIC5pY29uLW1pbnVzIHsgZGlzcGxheTogbm9uZTsgfVxuXG4gICAgICAuYy12YWNhdGlvbi1jYWxjdWxhdG9yLWZhcV9fZW50cnlfX2Fuc3dlciB7XG4gICAgICAgIGRpc3BsYXk6IG5vbmU7XG4gICAgICB9XG4gICAgfVxuXG4gICAgcGFkZGluZzogMWVtIDA7XG4gICAgYm9yZGVyLWJvdHRvbTogMXB4IHNvbGlkIHRoZW1lKCdjb2xvcnMuZ3JheS4zMDAnKTtcbiAgICAmOmxhc3Qtb2YtdHlwZSB7XG4gICAgICBib3JkZXItYm90dG9tOiBub25lO1xuICAgIH1cbiAgfVxufVxuIixudWxsXX0= */