import { Controller } from '@hotwired/stimulus'
import { Helpers } from '../../../utils/helpers'
import { toggle, isVisible } from '../../../utils/visibility'

export default class extends Controller {
  static targets = [
    'suggestions',
    'dropdown',
    'inputField',
  ]

  static outlets = [
    'pistachio--suggestions',
  ]

  connect() {
    Helpers.connectTargetAsOutlet(this, 'suggestions', 'pistachio--suggestions')

    const initial = JSON.parse(this.element.dataset.options)
    if (initial) {
      this.load(initial)
    }

    this.element.addEventListener('search-field:load!', event => this.load(event.detail))
  }

  load(options) {
    this.options = options
    this.pistachioSuggestionsOutlet.loadOptions(options)
    this.update()
  }

  onOpen(event) {
    this.inputFieldTarget.select()
    this.open()
  }

  onClose(event) {
    this.close()
  }

  onSuggestionsSelected(event) {
    // clear field
    this.inputFieldTarget.value = ''

    Helpers.emit(this.element, 'search-field:selected', event.detail)

    this.update()
    this.inputFieldTarget.blur() // close
  }

  onInputKey(event) {
    this.handleInput()
  }

  onInputChange(event) {
    this.handleInput()
  }

  handleInput() {
    if (this.inputFieldTarget.value != '' && !this.isOpen) {
      this.open()
    }

    this.update()
  }

  update() {
    this.pistachioSuggestionsOutlet.setFilter(this.inputFieldTarget.value)
    this.pistachioSuggestionsOutlet.update() // ensure virtual canvas of suggestion list is redrawn too
  }

  onKeyPress(event) {
    switch(event.key) {
      case 'Up': // IE/Edge
      case 'ArrowUp':
      case 'Down': // IE/Edge
      case 'ArrowDown':
      case 'Enter':
        if (!this.isOpen) {
          this.open()
        } else {
          this.pistachioSuggestionsOutlet.handleKey(event.key)
        }
        event.preventDefault()
        break;
    }
  }

  open() {
    toggle(this.dropdownTarget, true)
    this.update()
  }

  close() {
    toggle(this.dropdownTarget, false)
    this.update()
  }

  get isOpen() {
    return isVisible(this.dropdownTarget)
  }
}
