import { Controller } from '@hotwired/stimulus'
import { Helpers } from '../../../../utils/helpers'

export default class extends Controller {
  connect() {
    this.element.addEventListener('suggestions:selected', event => {
      const url = event.detail.url
      if (url) {
        Helpers.visit(url)
      } else {
        console.log('SuggestionsUrlHandler: No url found for', event)
      }
    })
  }
}
