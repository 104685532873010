import { Controller } from '@hotwired/stimulus'
import { toggle, isVisible } from '../../../utils/visibility'

export default class extends Controller {
  static targets = [
    'target',
  ]

  connect() {
    this.update()
  }

  toggleExclusive(event) {
    const el = event.target.closest('[data-toggle]')
    if (!el) return
    this.toggleTarget(el.dataset.toggle, true)
  }

  toggle(event) {
    const el = event.target.closest('[data-toggle]')
    if (!el) return
    this.toggleTarget(el.dataset.toggle)
  }

  update() {
    this.targetTargets.forEach(target => {
      const isExpanded = isVisible(target)

      this.element.querySelectorAll(`[data-toggle="${target.dataset.toggle}"]`).forEach(el => {
        if ('toggleShowWhenExpanded' in el.dataset) toggle(el, isExpanded)
        if ('toggleShowWhenCollapsed' in el.dataset) toggle(el, !isExpanded)

        if ('toggleDisabledWhenExpanded' in el.dataset) el.disabled = isExpanded
        if ('toggleDisabledWhenCollapsed' in el.dataset) el.disabled = !isExpanded

        if ('toggleClassWhenExpanded' in el.dataset) {
          const cls = el.dataset.toggleClassWhenExpanded.split(' ')
          cls.forEach(cl => el.classList.toggle(cl, isExpanded))
        }

        if ('toggleClassWhenCollapsed' in el.dataset) {
          const cls = el.dataset.toggleClassWhenCollapsed.split(' ')
          cls.forEach(cl => el.classList.toggle(cl, !isExpanded))
        }
      })
    })
  }

  toggleTarget(id, exclusive = false) {
    // Make sure we toggle first hidden elements, then visible elements
    // Otherwise scroll position can be jumpy because page height gets temporarily reduced (between the individual toggles)
    this.targetTargets
      .sort((t1, t2) => isVisible(t1) - isVisible(t2))
      .forEach(target => {
        if (target.dataset.toggle === id) {
          toggle(target)
        } else {
          if (exclusive) {
            // toggle others off
            toggle(target, false)
          }
        }
      })

    this.update()
  }
}
