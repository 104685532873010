import { Controller } from '@hotwired/stimulus'
import { toggle, show, hide } from 'pistachio'
import { Helpers } from 'pistachio'

export default class extends Controller {

  static targets = [
    'timeFrame',
    'periodStartDate',
    'periodEndDate',
    'periodStartDateInput',
    'periodEndDateInput',

    'hasJoinedDuringPeriod',
    'joinDate',

    'hasDepartedDuringPeriod',
    'departureDate',

    'hasChangedWorkloadDuringPeriod',

    'additionalEmployment',
    'toggleCalculationSummaryDetails',

    'numVacationDays',
    'numVacationWeeks',
  ]

  connect() {
    this.showCalculationSummaryDetails = false
    this.update()
  }

  update() {
    // joined during period
    const hasJoined = this.hasJoinedDuringPeriodTarget.checked
    toggle(this.joinDateTarget, hasJoined)

    // departed during period
    const hasDeparted = this.hasDepartedDuringPeriodTarget.checked
    toggle(this.departureDateTarget, hasDeparted)

    // workload changed
    const hasWorkloadChanged = this.hasChangedWorkloadDuringPeriodTarget.checked
    let elements = document.querySelectorAll('.vc-calculator-show-on-workload-change')
    elements.forEach(el => toggle(el, hasWorkloadChanged))

    // calc details
    const showCalcDetails = this.showCalculationSummaryDetails
    elements = document.querySelectorAll('.vc-calculation-summary-details')
    elements.forEach(el => toggle(el, showCalcDetails))
    if (this.hasToggleCalculationSummaryDetailsTarget) {
      const text = this.toggleCalculationSummaryDetailsTarget.getAttribute(`data-label-${showCalcDetails ? 'hide' : 'show'}`)
      this.toggleCalculationSummaryDetailsTarget.innerHTML = text
    }
  }

  handleTimeFrameSelection(event) {
    const data = event.detail.data || {}

    // Time frame toggle
    const startDate = data['start-date']
    if (startDate) { Helpers.emit(this.periodStartDateInputTarget, 'date:set!', startDate) }
    toggle(this.periodStartDateTarget, !startDate, 'flex')

    const endDate = data['end-date']
    if (endDate) { Helpers.emit(this.periodEndDateInputTarget, 'date:set!', endDate) }
    toggle(this.periodEndDateTarget, !endDate, 'flex')
  }

  onVacationDaysChange(event) {
    const numVacationDays = parseFloat(this.numVacationDaysTarget.value)
    if (isNaN(numVacationDays))
      return

    this.numVacationWeeksTarget.value = numVacationDays / 5.0
  }

  onVacationWeeksChange(event) {
    const numVacationWeeks = parseFloat(this.numVacationWeeksTarget.value)
    if (isNaN(numVacationWeeks))
      return

    this.numVacationDaysTarget.value = numVacationWeeks * 5.0
  }

  onToggleCalculationSummaryDetails(event) {
    this.showCalculationSummaryDetails = !this.showCalculationSummaryDetails
    this.update()
  }

  onPrint(event) {
    const beforeShowCalculationSummaryDetails = this.showCalculationSummaryDetails
    this.showCalculationSummaryDetails = true
    this.update()

    const mediaQueryList = window.matchMedia('print')
    const faq = document.querySelector('.c-vacation-calculator-faq')
    const intro = document.querySelector('.c-vacation-calculator-intro')

    hide(faq)
    hide(intro)

    const beforePrint = () => {

    }

    const afterPrint = () => {
      show(faq)
      show(intro)

      this.showCalculationSummaryDetails = beforeShowCalculationSummaryDetails
      this.update()
    }

    if (window.matchMedia) {
      const listener = mediaQueryList.addListener((mql) => {
        if(mql.matches) {
          beforePrint()
        } else {
          afterPrint()
          // afterprint
          mediaQueryList.removeListener(listener)
        }
      })
    }

    window.onbeforeprint = beforePrint
    window.onafterprint = afterPrint

    window.print()
  }
}
