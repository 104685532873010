import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'value']

  onSelect(event) {
    const option = event.target.closest('.c-grid-picker__option')

    this.inputTarget.value = option.getAttribute('data-value')
    this.valueTarget.innerHTML = option.innerHTML
  }
}
