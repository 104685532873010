import { Controller } from '@hotwired/stimulus'
import { toggle, Helpers } from 'pistachio'

export default class extends Controller {
  static targets = [
    'iban',
    'reference',
    'referenceField',
    'referenceNumber',
    'referenceNumberField',
    'result',
  ]

  QR_IBAN_IDENTIFIER = 'CH44'

  connect() {
    this.update()

    // scroll to result
    if (this.hasResultTarget && typeof(this.element.dataset.isSample) === 'undefined') {
      this.resultTarget.scrollIntoView(false)
    }
  }

  onUpdateIBAN(event) {
    this.update()
  }

  update() {
    const isQRIBAN = this.ibanTarget.value.toUpperCase().startsWith(this.QR_IBAN_IDENTIFIER)

    toggle(this.referenceTarget, !isQRIBAN)
    toggle(this.referenceNumberTarget, isQRIBAN)

    this.referenceFieldTarget.disabled = isQRIBAN
    this.referenceNumberFieldTarget.disabled = !isQRIBAN
  }

}
