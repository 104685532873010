import Appsignal from '@appsignal/javascript'
import { plugin as breadcrumbsNetworkPlugin } from '@appsignal/plugin-breadcrumbs-network'
import { plugin as breadcrumbsConsolePlugin } from '@appsignal/plugin-breadcrumbs-console'
import { plugin as windowEventsPlugin } from `@appsignal/plugin-window-events`

if (process.env.ENABLE_FRONTEND_ERROR_TRACKING == 'true') {
  window.Appsignal = new Appsignal({
    key:  /staging/.test(window.location.href) ? '83c440e1-40fc-49b5-8619-573ed50ec877' : 'af3e8b37-a8eb-42c2-b7d5-fd2d074b6478',
    revision: process.env.APP_REVISION,
  })

  window.Appsignal.use(breadcrumbsNetworkPlugin({}))
  window.Appsignal.use(breadcrumbsConsolePlugin({}))
  window.Appsignal.use(windowEventsPlugin({}))

  window.Appsignal.addDecorator((span) => {
    return span.setTags({ url: window.location.href });
  })

  // To get a more relevant grouping of errors (as most are just Error),
  // set the error name to <error.name>: <error.message>
  window.Appsignal.addDecorator((span) => {
    const error = span.serialize().error
    return span.setError({
      name: `${error.name}: ${error.message}`,
      message: error.message,
      stack: error.backtrace.join('\n'),
    })
  })
}


import { Helpers, Polyfills } from 'pistachio'
import Rails from '@rails/ujs'

Polyfills.checkAndRun(main)

function main(err) {

  require('pistachio/app/components')

  require('../component_styles')
  require('../stimulus_controllers')

  Rails.start()

  window.onload = function() {
    Helpers.clarifyEmailAddresses()
    Helpers.deobfuscateAttributesOnPage()
    Helpers.pollAndForward()
  }
}

import 'pistachio/app/base'
import '../globals.css'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_asset_tag helper in views (e.g <%= image_asset_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
import * as images from '../images/**/*'
images.default.map((image) => image)
