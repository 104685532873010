import { Controller } from '@hotwired/stimulus'
import { Helpers } from '../../../../utils/helpers'

export default class extends Controller {
  static targets = [
    'modal',
    'content',
  ]

  connect() {
    this.element.addEventListener('click', (event) => {
      let el = event.target.closest('[data-remote-modal-url]')
      if (!el || !el.getAttribute)
        return

      const url = el.getAttribute('data-remote-modal-url')
      if (!url)
        return

      Helpers.emit(this.modalTarget, 'remote-modal:open', { source: el })

      event.stopPropagation()

      Helpers.emit(this.modalTarget, 'modal:open!', { preload: true })

      Helpers.fetch('get', url).then(response => {
        if(response.ok) {
          response.html.then(html => {
            Helpers.emit(this.modalTarget, 'modal:content!', html)
          })
        }
        else {
          Helpers.emit(this.modalTarget, 'modal:close!')
        }
      })
    }, true) // mousedown
  }
}
