import { Controller } from '@hotwired/stimulus'
import { toggle } from 'pistachio'

export default class extends Controller {
  static targets = ['feature', 'screenshot']

  connect() {
    this.activeFeature = 'time-tracking'
    this.update()
  }

  onClick(event) {

    this.activeFeature = event.target.closest('[data-feature]').dataset.feature
    this.update()
  }

  update() {
    this.featureTargets.forEach(el => {
      const isActive = el.dataset.feature == this.activeFeature

      el.classList.toggle('bg-white', isActive)
      el.classList.toggle('shadow', isActive)

      toggle(el.querySelector('dd'), isActive)
    })

    this.screenshotTargets.forEach(el => {
      toggle(el, el.dataset.feature == this.activeFeature)
    })
  }
}
